import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { AxiosPost } from '../../../lib/utils3'
import Config from '../../../config'
import Modal from 'simple-react-modal'
import { ToastContainer, toast } from 'react-toastify'


import NovoContribuinte from "./contribuinte"
const toastSuccess = (mensagem) => {
  toast.success(mensagem, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });
};
const toastInfo = (mensagem) => {
  toast.info(mensagem, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  })
};
export default class lstContribuintes extends Component {

  state = {
    results: [],
    filteredResults: [],
    searchField: "cpf",
    searchPlaceholder: "CPF",
    totalByPage: 25,
    pagesTotal: 0,
    currentPage: 0,
    currentIndex: 0,
    finalPosition: 0,
    exclusionModal: {
      open: false,
      confirmed: false,
      name: '',
      id: null,
    },
  }

  setTotalPage() {
    this.setState({
      pagesTotal: Math.ceil(this.state.filteredResults.length / this.state.totalByPage),
    })
  }

  calculatePage(pageNumber) {
    if (pageNumber <= 0 || pageNumber > this.state.pagesTotal) return;
    let currentIndex = this.state.totalByPage * (pageNumber - 1)
    let finalPosition = this.state.totalByPage * pageNumber
    this.setState({
      currentPage: pageNumber,
      currentIndex,
      finalPosition,
    })
  }

  async componentDidMount() {
    this.searchTaxpayers()
    this.onClearSearchField()
  }

  changeSearchField(fieldName) {
    let plceholder = ""
    let searchField = ""
    switch (fieldName) {
      case 'cpf':
        plceholder = "CPF"
        searchField = "cpf"
        break;
      case 'nome':
        plceholder = "Nome"
        searchField = "nome"
        break;
      case 'email':
        plceholder = "Email"
        searchField = "email"
        break;
      case 'telefone':
        plceholder = "Telefone"
        searchField = "telefone"
        break;
    }
    document.getElementById("pesquisar").placeholder = plceholder
    this.setState({ searchField, searchPlaceholder: plceholder })
  }

  searchField() {
    let field = document.querySelector("#pesquisar").value
    let searchedResults = []
    switch (this.state.searchField) {
      case 'cpf':
        searchedResults = this.state.results.filter(
          (user) => {
            return (user.cpf) && user.cpf.includes(field)
          }
        )
        break;
      case 'telefone':
        searchedResults = this.state.results.filter(
          (user) => {
            return (user.telefone) && user.telefone.includes(field)
          }
        )
        break;
      case 'email':
        searchedResults = this.state.results.filter(
          (user) => {
            return (user.email) && user.email.includes(field)
          }
        )
        break;
      case 'nome':
        searchedResults = this.state.results.filter(
          (user) => {
            return (user.nome) && user.nome.toLowerCase().includes(field.toLowerCase())
          }
        )
        break;
    }
    this.setState({ filteredResults: searchedResults })
    this.setTotalPage()
    this.calculatePage(1)
  }

  clearSearch() {
    this.setState({ filteredResults: this.state.results })
    this.setTotalPage()
    this.calculatePage(1)
  }

  formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) return "-"
    if (!phoneNumber.match(/^\d{8,11}$/g)) return phoneNumber
    if (phoneNumber.match(/^(\d{4})(\d{4})$/g)) {
      return phoneNumber.replace(/^(\d{4})(\d{4})$/g, "$1-$2")
    } else if (phoneNumber.match(/^(\d{1})(\d{4})(\d{4})$/g)) {
      return phoneNumber.replace(/^(\d{1})(\d{4})(\d{4})$/g, "$1 $2-$3")
    } else if (phoneNumber.match(/^(\d{2})(\d{4})(\d{4})$/g)) {
      return phoneNumber.replace(/^(\d{2})(\d{4})(\d{4})$/g, "($1) $2-$3")
    } else if (phoneNumber.match(/^(\d{2})(\d{1})(\d{4})(\d{4})$/g)) {
      return phoneNumber.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})$/g, "($1) $2 $3-$4")
    }
  }

  formatCPF(cpf) {
    if (!cpf) return "-"
    if (!cpf.match(/^\d{11}$/g)) return cpf
    if (cpf.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/g)) {
      return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/g, "$1.$2.$3-$4")
    }
  }

  async searchTaxpayers(params = {}) {
    await AxiosPost(
      `${Config.dominio}/php/contribuintes/contribuintes_list.php`,
      JSON.stringify(params)
    )
      .then(async (response) => {
        let results = response.data.map(
          (user) => {
            let cpf = user.reg_user
            delete user.reg_user
            return {
              ...user,
              cpf,
              cpfFormatted: this.formatCPF(cpf),
              telefoneFormatted: this.formatPhoneNumber(user.telefone)
            }
          }
        )
        this.setState({ results, filteredResults: results })
        this.setTotalPage()
        this.calculatePage(1)
      })
      .catch(erro => console.error(erro))
  }

  onClearSearchField() {
    document.querySelector("#pesquisar").addEventListener(
      'keyup',
      (e) => {
        let value = e.target.value
        if (!value) {
          this.clearSearch()
        }
      }
    )
  }

  openModalExclusionConfirm(collaboratorID, collaboratorName) {
    this.setState({ exclusionModal: { open: true, confirmed: false, name: collaboratorName, id: collaboratorID } })
  }

  async excludeCollaborator() {
    const collaboratorID = this.state.exclusionModal.id
    await AxiosPost(`${Config.dominio}/php/contribuintes/contribuintes_delete.php`,
      JSON.stringify({ id: collaboratorID })
    )
      .then(async function (response) {
        this.setState({ exclusionModal: { open: false, confirmed: false, name: '', id: null } })
        if (response.data.status) {
          const newList = this.state.results.filter(collaborator => collaborator.id != collaboratorID)
          this.setState({ results: newList, filteredResults: newList })
          toastSuccess('Contribuinte Deletado!')
        } else {
          toastInfo('Não foi possivel deletar esse Contribuinte. Existe OS vinculada!')
        }
      }.bind(this))
      .catch((erro) => {
        console.error(erro)
      })
  }

  relatorio() {

    if (this.state.results) {
      if (this.state.results.length == 0) return (<center><h2>Não há contribuintes cadastrados.</h2></center>)

      return (
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>CPF</th>
              <th style={{ width: "1px", whiteSpace: "nowrap" }}>Nome</th>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Email</th>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Telefone</th>
              <th style={{ width: "175px", textAlign: "center", whiteSpace: "nowrap" }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {this.state.filteredResults.slice(this.state.currentIndex, this.state.finalPosition).map((item, index) =>
              <tr key={index}>
                <td style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>{item.cpfFormatted}</td>
                <td style={{ width: "1px", whiteSpace: "nowrap" }}>{item.nome}</td>
                <td style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>{item.email}</td>
                <td style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>{item.telefoneFormatted}</td>
                <td style={{ textAlign: "center" }}>
                  <div className="btn-group">
                    <button type="button" className="btn btn-success" onClick={(e) => ReactDOM.render(<NovoContribuinte id={item.id} cpf={item.cpf} nome={item.nome} telefone={item.telefone} email={item.email} />, document.getElementById('Content'))}><i className="fa fa-edit"></i></button>
                    <button type="button" className="btn btn-danger" onClick={(e) => this.openModalExclusionConfirm(item.id, item.nome)}><i className="fa fa-trash"></i></button>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>CPF</th>
              <th style={{ width: "1px", whiteSpace: "nowrap" }}>Nome</th>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Email</th>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Telefone</th>
              <th style={{ width: "175px", textAlign: "center", whiteSpace: "nowrap" }}>Ações</th>
            </tr>
          </tfoot>
        </table>
      )
    }
  }

  render() {
    return (
      <>
        <ToastContainer />
        <Modal
          show={this.state.exclusionModal.open}
          containerStyle={{ background: 'Transparent' }}
          transitionSpeed={100}
        >
          <div className="box box-danger">
            <div className="box-body">
              <div className="row">
                <div className="col-xs-12">
                  <center>
                    <h4>O contribuinte <b>{this.state.exclusionModal.name}</b> será excluído. Confirma a exclusão?</h4>
                  </center>
                </div>
              </div>
            </div>
            <div className="box-footer">
              <center>
                <button
                  id="btnExcluir"
                  onClick={() => this.excludeCollaborator()}
                  className="btn btn-danger btn-flat"
                >
                  Excluir
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button
                  onClick={() => this.setState({ exclusionModal: { open: false, confirmed: false, name: '', id: null } })}
                  className="btn btn-warning btn-flat"
                >
                  Cancelar
                </button>
              </center>
            </div>
          </div>
        </Modal>
        <section className="content-header">
          <h1>
            Contribuintes
            <small>Listagem geral</small>
          </h1>
          <ol className="breadcrumb">
            <button className="btn btn-block btn-primary" onClick={() => ReactDOM.render(<NovoContribuinte />, document.getElementById('Content'))}> Cadastro de Contribuinte</button>
          </ol>
        </section>
        <section className="content">
          <div className="box">
            <p />
            <div className="col-md-6">
              <div className="input-group">
                <div className="input-group-btn">
                  <button type="button" className="btn btn-warning dropdown-toggle" data-toggle="dropdown">Filtro&nbsp;&nbsp;
                    <span className="fa fa-caret-down"></span></button>
                  <ul className="dropdown-menu">
                    <li><a href="#" onClick={() => this.changeSearchField("cpf")}>CPF</a></li>
                    <li><a href="#" onClick={() => this.changeSearchField("nome")}>Nome</a></li>
                    <li><a href="#" onClick={() => this.changeSearchField("email")}>Email</a></li>
                    <li><a href="#" onClick={() => this.changeSearchField("telefone")}>Telefone</a></li>
                  </ul>
                </div>
                <input type="text" id="pesquisar" className="form-control" placeholder={this.state.searchPlaceholder} />
                <span className="input-group-btn">
                  <button type="button" className="btn btn-info btn-flat" onClick={(e) => this.searchField()}>Pesquisar</button>
                </span>
              </div>
            </div>
            <div className="box-tools">
              <ul className="pagination pagination-sm no-margin pull-right">
                {this.state.currentPage > 1 && <li><a href="#" onClick={() => this.calculatePage(this.state.currentPage - 1)}>&laquo;</a></li>}
                <li>
                  <a>
                    {this.state.currentPage}
                  </a>
                </li>
                {this.state.currentPage < this.state.pagesTotal && <li><a href="#" onClick={() => this.calculatePage(this.state.currentPage + 1)}>&raquo;</a></li>}
              </ul>
            </div>
          </div>
          <div className="box-body">
            <p />
            <p />
            {this.relatorio()}
          </div>
        </section>
      </>
    )
  }
}