import React, { useState } from "react";
import ReactSwitch from "react-switch";
import { TIPOS_AGENDAMENTOS } from "./enumTelegestao";

const DeviceControl = (props) => {
  const [readOnlyDataState, setReadOnlyDataState] = useState(true);
  const [dateState, setDateState] = useState("");
  const [timeState, setTimeState] = useState("");
  const [typeCommandState, setTypeCommandState] = useState("");
  const [scheduleState, setScheduleState] = useState([])

  let sch = [];

  const tratarReadOnlyData = (elemento) => {
    const valor = elemento.options[elemento.selectedIndex].value
    setReadOnlyDataState(valor !== TIPOS_AGENDAMENTOS.EVENTO)
  }

  const onChangeTipoComando = (elemento) => {
    tratarReadOnlyData(elemento)
    setTypeCommandState(elemento.options[elemento.selectedIndex].value)
  }

  const onChangeDate = (event) => {
    setDateState(event.target.value)
  }

  const onChangeTime = (event) => {
    setTimeState(event.target.value)
  }

  const adicionarAgendamento = () => {
    if(validarTipoEvento() === false) {
      alert('Selecione o tipo de comando de agendamento')
      return;
    }
    if(validarHora() === false) {
      alert('Selecione um horário de execução do comando')
      return;
    }

    if(validarData() === false) {
      alert('Selecione uma data para a execução do comando')
      return;
    }
    const date = dateState ? dateState.split('-'): '';
    const schedule = { type: typeCommandState, date: date ? `${date[2]}-${date[1]}-${date[0]}`: '', time: timeState, state: props.estadoLumi, dimmer: props.dimmer };
    props.setScheduleList([...props.scheduleList, schedule]);

  }

  const removerAgendamento = (event, index) => {
    // console.log('removerAgendamento event', event);
    // console.log('removerAgendamento index', index);
    // props.setScheduleList([...props.scheduleList.splice(index, 1)]);
    const updatedList = [...props.scheduleList];
    updatedList.splice(index, 1);
    props.setScheduleList(updatedList);
  }

  const validarTipoEvento = () => {
    const elemento = document.getElementById('tipo-comando');
    if(elemento.options[elemento.selectedIndex].value === '') {
      return false;
    }
    return true;
  }

  const validarHora = () => {
    if(document.getElementById('schedule-time').value === '') {
      return false;
    }
    return true;
  }

  const validarData = () => {
    const elemento = document.getElementById('tipo-comando');
    const tipo = elemento.options[elemento.selectedIndex].value;
    
    if(tipo === TIPOS_AGENDAMENTOS.EVENTO && document.getElementById('schedule-date').value === '') {
      return false;
    }
    return true;
  }

  const validarAgendamento = () => {
    if(props.scheduleList.length === 0) {
      return false;
    }
    return true;
  }
  const enviarAgendamento = (event) => {
    if(validarAgendamento() === false) {
      alert('Por favor adicione um agendamento antes de enviar');
      return;
    }
    props.submit(event, typeCommandState);
  }

  return (
    <div className="nav-tabs-custom">
      <ul id="tab_itens" className="nav nav-tabs">
        <li className="active"><a href="#controlar" data-toggle="tab">Controle</a></li>
        <li><a href="#agendar" data-toggle="tab">Agendamento</a></li>
      </ul>
      <div className="tab-content">
        <div className="active tab-pane" id="controlar">
          <div className="row">
            <div className="col-xs-2">
              <label>Ligar/Desligar</label>
              <ReactSwitch
                checked={props.estadoLumi}
                onChange={() => { props.setEstadoLumi(!props.estadoLumi) }}
              />
            </div>
            <div className="col-xs-8">
              <label className="form-label">Dimerizar:</label>
              <div className="input-group input-group-sm">
                <input type="range" className="form-range" min="0" max="100" step="10" value={props.dimmer} onChange={(event) => { props.setDimmer(event.target.value) }} />
                <span className='input-group-btn'>
                  <div className='btn btn-info'>{props.dimmer}</div>
                </span>
              </div>
            </div>
            <div className="col-xs-2">
              <div className="form-group">
                <div className="text-center">
                  <label>Enviar</label>
                </div>
                <button type="button" className="btn btn-flat btn-success form-control" onClick={(event) => { props.sentCommand(event) }}>Enviar</button>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-pane" id="agendar">
          <form>
            <div className="row">
              <div className="col-xs-4">
                <div className="form-group">
                  <label>Tipo comando</label>
                  <select id="tipo-comando" className="form-control" onChange={() => { onChangeTipoComando(document.getElementById('tipo-comando')) }} required>
                    <option value=""> Selecione</option>
                    <option value={TIPOS_AGENDAMENTOS.DIARIO}> Diário</option>
                    <option value={TIPOS_AGENDAMENTOS.EVENTO}> Evento </option>
                  </select>
                </div>
              </div>
              <div className="col-xs-4">
                <div className="form-group">
                  <label>Data</label>
                  <input id="schedule-date" type="date" className="form-control" disabled={readOnlyDataState} onChange={(event) => { onChangeDate(event) }}></input>
                </div>
              </div>
              <div className="col-xs-4">
                <div className="form-group">
                  <label>Horário de Execução (hh:mm)</label>
                  <div className="d-flex flex-row bd-highlight mb-3">
                    <input id="schedule-time" type="time" onChange={(event) => { onChangeTime(event) }} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-2">
                <label>Ligar/Desligar</label>
                <ReactSwitch
                  checked={props.estadoLumi}
                  onChange={() => { props.setEstadoLumi(!props.estadoLumi) }}
                />
              </div>
              <div className="col-xs-8">
                <label className="form-label">Dimerizar:</label>
                <div className="input-group input-group-sm">
                  <input type="range" className="form-range" min="0" max="100" step="10" value={props.dimmer} onChange={(event) => { props.setDimmer(event.target.value) }} />
                  <span className='input-group-btn'>
                    <div className='btn btn-info'>{props.dimmer}</div>
                  </span>
                </div>
              </div>
              <div className="col-xs-2">
                <div className="form-group">
                  <div className="text-center">
                    <label>Adic./Enviar</label>
                  </div>
                  <div className="btn-group">
                    <button type="button" className="btn btn-primary btn-flat" onClick={adicionarAgendamento}><i className="fa fa-plus" aria-hidden="true"></i></button>
                    <button type="button" className="btn btn-flat btn-success" onClick={(event) => enviarAgendamento(event)}><i className="fa fa-arrow-right" aria-hidden="true"></i></button>
                  </div>
                </div>
              </div>
              <div className="col-xs-12">
                {1 ? <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>Data</th>
                      <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>Horário</th>
                      <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>Estado</th>
                      <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>Dimmer</th>
                      <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>Ação</th>
                    </tr>
                  </thead>
                  <tbody id="table_agendamento">
                    {props.scheduleList.map((schedule, index) => (<tr key={index}>
                      <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>{schedule?.date ? schedule?.date : 'Diário'}</td>
                      <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>{schedule?.time}</td>
                      <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>{schedule?.state ? 'Ligar' : 'Desligar'}</td>
                      <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>{schedule?.dimmer}</td>
                      <td style={{ textAlign: "center", whiteSpace: "nowrap" }}><button type="button" className="btn btn-danger" onClick={(event) => { removerAgendamento(event,index) }}>
                              <i className="fa fa-trash"></i>
                            </button></td>
                    </tr>))}
                  </tbody>
                </table> : ""}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
export default DeviceControl