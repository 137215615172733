import React, { useEffect, useState } from "react";
import { useLeafletContext } from "@react-leaflet/core";
import { polygon } from "leaflet";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

const AreaGrupo = (props) => {
  const [currentLayerState, setCurrentLayerState] = useState(null);
  const context = useLeafletContext();

  useEffect(() => {
    const leafletContainer = context.layerContainer || context.map;
    props.setMapInstance(leafletContainer);

    leafletContainer.pm.setLang("pt_br");

    leafletContainer.pm.addControls({
      drawMarker: false,
      drawPolyline: false,
      drawRectangle: false,
      drawCircle: false,
      cutPolygon: false,
      drawCircleMarker: false,
    });

    leafletContainer.on("pm:create", (e) => {
      const layerPoints = e.layer.getLatLngs();
      const layer = e.layer;

      if (currentLayerState && leafletContainer.hasLayer(currentLayerState)) {
        leafletContainer.removeLayer(currentLayerState);
      }

      setCurrentLayerState(layer);
      props.setPontosArea(layerPoints[0]);

      layer.addTo(leafletContainer);

      layer.on("pm:edit", handleEdit);
    });

    return () => {
      leafletContainer.pm.removeControls();
      leafletContainer.off("pm:create");
    };
  }, [context]);

  useEffect(() => {
    const leafletContainer = context.layerContainer || context.map;
    const { pontosArea } = props;

    if (currentLayerState && leafletContainer.hasLayer(currentLayerState)) {
      leafletContainer.removeLayer(currentLayerState);
    }

    if (pontosArea && pontosArea.length > 0) {
      const layer = polygon(pontosArea);
      setCurrentLayerState(layer);

      layer.addTo(leafletContainer);

      layer.on("pm:edit", handleEdit);
    } else if (currentLayerState) {
      setCurrentLayerState(null);
    }
  }, [props.pontosArea]);

  const handleEdit = ({ layer }) => {
    console.log("Polígono Editado:", layer.getLatLngs()[0]);
    props.setPontosArea(layer.getLatLngs()[0]);
  };

  return null;
};

export default AreaGrupo;
