import React from 'react'
import MapaDashBoard from "./actionParts/dashboard/mapaDashboard"
export default function mainActions (props) {
 
  return (
      <div id="Content" className="content-wrapper">
        <MapaDashBoard/>
      </div>
    )
  
}
