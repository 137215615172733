
import React from 'react'
import ReactDOM from 'react-dom'
import ClipLoader from "react-spinners/ClipLoader"
import Modal from 'simple-react-modal'
import Baixa from './baixa'
import { format } from 'date-fns'
import { AxiosPost } from '../../../../lib/utils3'
import { usuario } from '../../../../lib/utils2'
//import ReactTooltip from 'react-tooltip'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import Config from '../../../../config'
import moment from 'moment'
//import Tippy from '@tippyjs/react';
//import 'tippy.js/dist/tippy.css'; // optional

const animatedComponents = makeAnimated();
const btnStyle = {
  margin: '2px'
};

export default class lstAcompanhamentoOS extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      result: [],
      osTransferir: null,
      lstEquipe: [],
      showImpressao: false,
      osImpressao: null,
      novaEquipe: null,
      showTransfereOS: false,
      lstStatus: null,
      lstBairros: [],
      bairro: [],
      lstDistritos: [],
      distrito: [],
      filtroValor: "",
      filtroPlaceholder: "OS",
      showOSFalsa: false,
      osFalsa: null,
      loading: true,
      paginacao: 20,
      totalRegistros: 0,
      pag_atual: 1,
      vencimento: 0,
      aux: false,
      formataContribuinte: "",
      lstTipoOs: [{ value: '0', label: 'MANUTENÇÃO' },
      { value: '1', label: 'IMPLANTAÇÃO/OBRAS' }],
      selectEquipe: [],
      tipoOs: [],
      dataFiltro: [],
      filtroOrdensServico: [],
      selectedOption: null,
      //isClearable: true
    };
    this.handleTransferir = this.handleTransferir.bind(this);
    this.listaOrdensServico = this.listaOrdensServico.bind(this);

  }

   inputSelectBusca (e) {
    if(e?.value){
        let arrayFiltrado = 
          this.state.filtroPlaceholder === "Tipo de os" 
            ? this.state.dataFiltro.filter((os) => os.tipo_os?.includes(e.value))
            : this.state.filtroPlaceholder === "Bairro"
              ? this.state.dataFiltro.filter((os) => os.bairro?.includes(e.value))
              : this.state.filtroPlaceholder === "Equipe" 
                ? this.state.dataFiltro.filter((os) => parseInt(os.equipe) === parseInt(e.value))
                : []
        this.setState({ filtroOrdensServico: arrayFiltrado, filtroValor: e.value });
    } else {
      this.setState({ filtroValor: "" })
    }
  }

  buscaInput = e => {
   e.preventDefault();
   let valor = e.target.value 

    let arrayFiltrado = 
    valor.length > 4 && this.state.filtroPlaceholder === "OS"
    ? this.state.dataFiltro.filter((os) => os.protocolo.includes(valor))
    : valor.length > 8 && this.state.filtroPlaceholder === "Data de Abertura"
    ? this.state.dataFiltro.filter((os) => os.datahora_abertura.includes(valor))
    : valor.length > 6 && this.state.filtroPlaceholder === "Logradouro"
    ? this.state.dataFiltro.filter((os) => os.rua.includes(valor))
    : [];

    this.setState({ filtroOrdensServico: arrayFiltrado, filtroValor: valor });
  }

  async handleTransferir(event) {
    event.preventDefault()
    let _equipe = this.state.novaEquipe ? this.state.novaEquipe : this.state.lstEquipe.filter(f => f.id != this.state.osTransferir.equipe)[0].id
    let _observacao = `Transferência entre equipes (de ${this.state.osTransferir.nome_equipe} para ${this.state.lstEquipe.filter(f => f.id == _equipe)[0].nome})`
    await AxiosPost(`${Config.dominio}/php/os/ordemServico_distribuir.php`,
      JSON.stringify(
        {
          lista: this.state.osTransferir.protocolo,
          equipe: _equipe,
          observacao: _observacao
        }
      )
    )
      .then(async function (response) {
        toast.success('Ordem de Serviço transferida entre as equipes.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        this.setState({
          osTransferir: null,
          lstEquipe: [],
          dataFiltro: [],
          novaEquipe: null,
          showTransfereOS: false,
          result: [],
          filtroValor: ""
        })
        this.listaOrdensServico();
        this.filtro();
        this.tecnicos();
      }.bind(this))
  }

  async tecnicos() {
    await AxiosPost(`${Config.dominio}/php/cadastro/equipe_trabalho/tecnicos/get.php`, JSON.stringify({ a: 1 }))
      .then(async function (response) {
        this.setState({ lstEquipe: response.data, selectEquipe: response.data.map(tecnico => ({value: tecnico.id, label: tecnico.nome})) })
      }.bind(this))
      .catch(erro => console.error(erro))
  }

  async status() {
    await AxiosPost(`${Config.dominio}/php/generica_list.php`,
      JSON.stringify({ tabela: 5 })
    )
      .then(async response => this.setState({ lstStatus: response.data }))
      .catch(erro => console.error(erro))
  }

  dataDiff(dataAberturaOs) {
    let dataAtual = moment().format("DD/MM/YYYY HH:mm:ss");
    let d2 = dataAberturaOs;
    let diff = moment(dataAtual, "DD/MM/YYYY HH:mm:ss").diff(moment(d2, "DD/MM/YYYY HH:mm:ss"));
    let dias = moment.duration(diff).asDays();

    if (dias <= 7) {
      this.state.vencimento = dias;
      return 0;
    } else if (dias > 7 && dias <=8) {
      this.state.vencimento = dias;
      return 1;
    } else {
      this.state.vencimento = dias;
      return 2;
    }
  }

  formataNomeContribuinte(nomeContribuinte) {
    if (nomeContribuinte != null) {
      let text = nomeContribuinte
      const myArray = text.split(" ")
      return myArray[0]
    } else {
      return " "
    }   
  }


  relatorio(arrayOs) {
    if (this.state.arrayOs && this.state.arrayOs.length === 0) {
      return <center><h2>Nenhuma ordem de serviço encontrada.</h2></center>
    } else {
      return (
        <>
           <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Emissão</th>
                <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>OS</th>
                <th style={{ width: "1px", whiteSpace: "nowrap" }}>Equipe</th>
                <th style={{ whiteSpace: "nowrap" }}>Descrição do problema</th>
                <th style={{ width: "1px", whiteSpace: "nowrap" }}>Tipo OS</th>
                <th style={{ width: "1px", whiteSpace: "nowrap" }}>Solicitante</th>
                <th style={{ width: "1px", whiteSpace: "nowrap" }}>Dias</th>
                <th style={{ width: "175px", textAlign: "center", whiteSpace: "nowrap" }}>Ações</th>
              </tr>
            </thead>
            <tbody>
              {arrayOs.map((item, index) =>

                <tr key={index}>
                  <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                    {this.dataDiff(format(new Date(item.datahora_abertura), "dd/MM/yyyy HH:mm")) == 0 ?
                      <p className='text-green'>{format(new Date(item.datahora_abertura), "dd/MM/yy HH:mm")}</p> :
                      this.dataDiff(format(new Date(item.datahora_abertura), "dd/MM/yyyy HH:mm")) == 1 ?
                        <p className='text-yellow'>{format(new Date(item.datahora_abertura), "dd/MM/yy HH:mm")}</p> :
                        <p className='text-red'>{format(new Date(item.datahora_abertura), "dd/MM/yy HH:mm")}</p>
                    }
                  </td>
                  
                  <td style={{ textAlign: "center", whiteSpace: "nowrap" }} id="dataTip">
                  
                    {this.dataDiff(format(new Date(item.datahora_abertura), "dd/MM/yyyy HH:mm")) == 0 ?
                    <span className="text-green" id="dataTip">{item.protocolo}</span>:
                      this.dataDiff(format(new Date(item.datahora_abertura), "dd/MM/yyyy HH:mm")) == 1 ?
                        <span className="text-yellow" id="dataTip">{item.protocolo}</span> :
                      <span className="text-red" id="dataTip">{item.protocolo}</span>
                    }
                 
                 
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {this.dataDiff(format(new Date(item.datahora_abertura), "dd/MM/yyyy HH:mm")) == 0 ?
                      <span className="text-green">{item.equipe_tecnicos}</span> :
                      this.dataDiff(format(new Date(item.datahora_abertura), "dd/MM/yyyy HH:mm")) == 1 ?
                        <span className="text-yellow">{item.equipe_tecnicos}</span> :
                        <span className="text-red">{item.equipe_tecnicos}</span>
                    }
                  </td>

                  <td style={{ whiteSpace: "nowrap" }}>
                    {this.dataDiff(format(new Date(item.datahora_abertura), "dd/MM/yyyy HH:mm")) == 0 ?
                      <span className="text-green" data-tip={item.descricao_problema}>{item.desc_problema}</span> :
                      this.dataDiff(format(new Date(item.datahora_abertura), "dd/MM/yyyy HH:mm")) == 1 ?
                        <span className="text-yellow" data-tip={item.descricao_problema}>{item.desc_problema}</span> :
                        <span className="text-red" data-tip={item.descricao_problema}>{item.desc_problema}</span>
                    }
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {this.dataDiff(format(new Date(item.datahora_abertura), "dd/MM/yyyy HH:mm")) == 0 ?
                      <span className="text-green">{item.tipo_os === '0' ? 'MANUTENÇÃO' : item.tipo_os === '1' ? 'IMPLANTAÇÃO/OBRAS' : ''}</span> :
                      this.dataDiff(format(new Date(item.datahora_abertura), "dd/MM/yyyy HH:mm")) == 1 ?
                        <span className="text-yellow">{item.tipo_os === '0' ? 'MANUTENÇÃO' : item.tipo_os === '1' ? 'IMPLANTAÇÃO/OBRAS' : ''}</span> :
                        <span className="text-red">{item.tipo_os === '0' ? 'MANUTENÇÃO' : item.tipo_os === '1' ? 'IMPLANTAÇÃO/OBRAS' : ''}</span>
                    }
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>
    
                    {this.dataDiff(format(new Date(item.datahora_abertura), "dd/MM/yyyy HH:mm")) == 0 ?
                      <span className="text-green" data-tip={item.nome_contribuinte}>{this.formataNomeContribuinte(item.nome_contribuinte)}</span> :
                      this.dataDiff(format(new Date(item.datahora_abertura), "dd/MM/yyyy HH:mm")) == 1 ?
                        <span className="text-yellow" data-tip={item.nome_contribuinte}>{this.formataNomeContribuinte(item.nome_contribuinte)}</span> :
                        <span className="text-red" data-tip={item.nome_contribuinte}>{this.formataNomeContribuinte(item.nome_contribuinte)}</span>
                    }
        
                  </td>
                  

                  <td style={{ textAlign: "center" }}>
                    {this.dataDiff(format(new Date(item.datahora_abertura), "dd/MM/yyyy HH:mm")) == 0 ?
                      <small className="label bg-green">{Math.round(this.state.vencimento)}</small> :
                      this.dataDiff(format(new Date(item.datahora_abertura), "dd/MM/yyyy HH:mm")) == 1 ?
                        <small className="label bg-yellow">{Math.round(this.state.vencimento)}</small> :
                        <small className="label bg-red">{Math.round(this.state.vencimento)}</small>
                    }
                  </td>

                  <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                    <div className="btn-group">
                    
                      <button style={btnStyle} type="button" className="btn btn-success btn-sm" onClick={() => ReactDOM.render(<Baixa protocolo={`${item.protocolo}`} acao={`baixar`} />, document.getElementById('Content'))}><i className="fa  fa-thumbs-o-up"></i></button>
                  
                      <button style={btnStyle} type="button" className="btn btn-warning btn-sm" onClick={() => this.setState({ showTransfereOS: true, osTransferir: item })}><i className="fa fa-exchange"></i></button>
                  
                      <button style={btnStyle} type="button" className="btn btn-info btn-sm" onClick={() => this.setState({ showImpressao: true, osImpressao: item.protocolo })}><i className="fa  fa-print"></i></button>
                    
                      <button style={btnStyle} type="button" className="btn btn-danger btn-sm" onClick={() => this.setState({ showOSFalsa: true, osFalsa: item })}><i className="fa  fa-thumbs-o-down"></i></button>
                   
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
            </tfoot>
          </table> 

        </>
      )
    }
  }

  async confirmarOSFalsa() {

    //this.state.osFalsa

    await AxiosPost(`${Config.dominio}/php/os/ordemServico_falsa.php`,
      JSON.stringify({ OS: this.state.osFalsa.protocolo })
    )
      .then(async function (response) {
        toast.success('Ordem de Serviço encerrada como Notificação Falsa.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        this.setState({ showOSFalsa: false, osFalsa: null, dataFiltro: [], result: [], filtroValor: "" })
        this.filtro();
        this.listaOrdensServico();
      }.bind(this))
      .catch(erro => console.error(erro))
  }

  async paginacao(pagina) {
    let pag_atual = this.state.pag_atual
    this.setState({ pag_atual: pag_atual + pagina, loading: true, result: []})
    await this.listaOrdensServico()
    this.setState({ loading: false })
  }

  async listaOrdensServico() {
    await AxiosPost(`${Config.dominio}/php/os/ordemServicoAcompanhamento_list.php`, JSON.stringify({
      paginacao: this.state.paginacao,
      pagina: this.state.pag_atual,
      status: 3
    }))

      .then(async function (response) {

        this.setState({
          result: response.data,
          loading: false,
        })
      }.bind(this))
      .catch(erro => console.error(erro))
  }

  async filtro() {
    await AxiosPost(`${Config.dominio}/php/os/ordemServicoAcompanhamento_list.php`, JSON.stringify({
      status: 3
    }))

      .then(async function (response) {

        let bairros = []
        let _bairros = []
        response.data.map(item => bairros.push(item.bairro))
        bairros = [...new Set(bairros)]
        bairros.map(item => item != "null" && _bairros.push({ value: item, label: item }))

        this.setState({
          dataFiltro: response.data,
          lstBairros: _bairros,
        })
      }.bind(this))
      .catch(erro => console.error(erro))
  }

  async pesquisar() {
    let filtroValor = this.state.filtroValor
    let filtroPlaceholder = this.state.filtroPlaceholder
    let _lstBairros = []
    this.state.bairro.length > 0 && this.state.bairro.map(item => _lstBairros.push(item.value))
    let _lstDistritos = []
    this.state.distrito.length > 0 && this.state.distrito.map(item => _lstDistritos.push(item.value))
    await AxiosPost(`${Config.dominio}/php/os/ordemServicoAcompanhamento_list.php`,

      JSON.stringify({
        filtro_barramento: filtroPlaceholder == 'Barramento' ? filtroValor : '',
        filtro_distrito: filtroPlaceholder == 'Distrito' ? _lstDistritos.toString() : '',
        filtro_bairro: filtroPlaceholder == 'Bairro' ? _lstBairros.toString() : '',
        filtro_rua: filtroPlaceholder == 'Logradouro' ? filtroValor : '',
        filtro_tipo_os: filtroPlaceholder == 'Tipo de os' ? this.state.tipoOs.value : '',
        filtro_os: filtroPlaceholder == 'OS' ? filtroValor : '',
        filtro_dataAbertura: filtroPlaceholder == 'Data de Abertura' ? this.state.dataAbertura : '',
        status: 3
      })
    )

      .then(async function (response) {
        let bairros = []
        let _bairros = []
        let distritos = []
        let _distritos = []
        if (this.state.filtroPlaceholder == 'StatusOS' && this.state.status.length > 0) {
          response.data = response.data.filter(item => this.state.status.map(st => st.value).includes(item.id_status_atual))
        }
        response.data.map(item => bairros.push(item.bairro))
        bairros = [...new Set(bairros)]
        bairros.map(item => item != "null" && _bairros.push({ value: item, label: item }))
        response.data.map(item => distritos.push(item.distrito + '|' + item.id_distrito))
        distritos = [...new Set(distritos)]
        distritos.map(item => item != "null|null" && _distritos.push({ value: item.split("|")[1], label: item.split("|")[0] }))
        this.setState({
          result: response.data,
          totalRegistros: response.data.length,
          lstBairros: _bairros,
          lstDistritos: _distritos,
          loading: false
        })
      }.bind(this))
      .catch(erro => console.error(erro))
  }

  componentDidMount() {
    this.tecnicos()
    this.status()
    this.listaOrdensServico()
    this.filtro()
  }



  render() {
    return (
      <>
        <ToastContainer />
        <Modal
          show={this.state.showTransfereOS}
          containerStyle={{ background: 'white', width: '1014px', minHeight: '410px' }}
          transitionSpeed={100}
        >
          <form onSubmit={this.handleTransferir}>
            <section className="content">
              <center><h3>Transferir OS entre equipes</h3></center>
              <div className="col-md-6">
                <div className="box box-warning">
                  <div className="box-body">
                    <div className="row">
                      <div className="col-xs-12">
                        <h4>OS selecionada: {this.state.osTransferir && this.state.osTransferir.protocolo}</h4>
                        {this.state.osTransferir && this.state.osTransferir.desc_problema}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="col-md-6">
                <div className="box box-warning">
                  <div className="box-body">
                    <div className="row">
                      <div className="col-xs-12">
                        <label htmlFor="contribuinte">Equipe Atual</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled="disabled"
                          defaultValue={this.state.osTransferir && this.state.osTransferir.equipe_tecnicos}
                          placeholder="Sem equipe definida."
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12">
                        <label htmlFor="transferirPara">Transferir para:</label>
                        <select
                          className="form-control select2"
                          style={{ width: '100%' }}
                          onChange={e => this.setState({ novaEquipe: e.target.value })}
                        >
                          {
                            this.state.osTransferir && this.state.lstEquipe.filter(f => f.id != this.state.osTransferir.equipe).map((item, index) => <option key={index} value={item.id}>{item.nome}</option>)
                          }
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <br />
                      <center>
                        <button
                          id="btnCancelarOS"
                          type="button"
                          onClick={e => this.setState({ showTransfereOS: false, novaEquipe: null })}
                          className="btn btn-warning btn-flat"
                        >
                          Cancelar
                        </button>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <button
                          type="submit"
                          className="btn btn-info btn-flat"
                        >
                          Transferir
                        </button>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </form>
        </Modal>
        <Modal
          show={this.state.showOSFalsa}
          containerStyle={{ background: 'white', width: '507px', minHeight: '410px' }}
          transitionSpeed={100}
        >
          <section className="content">
            <center><h3>Notificação de OS falsa</h3></center>
            <div className="col-md-12">
              <div className="box box-warning">
                <div className="box-body">
                  <div className="row">
                    <div className="col-xs-12">
                      <h4>OS selecionada: {this.state.osFalsa && this.state.osFalsa.protocolo}</h4>
                      {this.state.osFalsa && this.state.osFalsa.desc_problema}
                    </div>
                  </div>
                </div>
                <div className="box-footer">
                </div>
              </div>
              <div className="box box-info">
                <div className="box-body">
                  <div className="row">
                    <div className="col-xs-12">
                      Esta OS será encaminhada para o setor responsável para que sejam tomadas as medidas cabíveis conforme a lei.
                    </div>
                  </div>
                  <div className="row">
                    <br />
                    <center>
                      <button
                        type="button"
                        onClick={e => this.setState({ showOSFalsa: false, osFalsa: null })}
                        className="btn btn-warning btn-flat"
                      >
                        Cancelar
                      </button>

                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                      <button
                        type="button"
                        className="btn btn-info btn-flat"
                        onClick={() => this.confirmarOSFalsa()}
                      >
                        Confirmar
                      </button>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Modal>
        <Modal
          show={this.state.showImpressao}
          containerStyle={{ background: 'white', width: '507px', minHeight: '250px' }}
          transitionSpeed={100}
        >
          <section className="content">
            <center>
              <h3>Imprimir Ordens de Serviço</h3>
              <a href="#"
                className="btn btn-primary btn-block margin-bottom"
                onClick={() => {
                  document.forms["formPrint"].submit()
                  this.setState({ showImpressao: false, os: null })
                }
                }
              >
                Imprimir
              </a>
              <p />
              <form
                target="_blank"
                id="formPrint"
                action={`${Config.dominio}/php/osAbertaIndividual.php`}
                method="post"
              >
                <input type="hidden" name="municipio" value={usuario().municipio.razaoSocial} />
                <input type="hidden" name="os" value={this.state.osImpressao} />
                <input type="hidden" name="empresa" value={usuario().nomeCliente} />
                <input type="hidden" name="usuario" value={usuario().nome} />
                <input type="hidden" name="local" value={sessionStorage.getItem('local')} />
                <input type="hidden" name="chaveMunicipio" value={usuario().municipio.chave} />
                <input type="checkbox" name="mapa" value="1" /> Incluir Mapa do ponto.
              </form>
              <p />
              <a href="#"
                className="btn btn-warning btn-block margin-bottom"
                onClick={() => {
                  this.setState({ showImpressao: false })
                }
                }
              >
                Fechar
              </a>
            </center>
          </section>
        </Modal>
        <section className="content-header">
          <h1>
            Acompanhamento das Ordens de Serviço
            <small>Listagem geral</small>
          </h1>
          <ol className="breadcrumb">
          </ol>
        </section>
        <section className="content">
          <div className="row">
            <div className="box">
              <p />
              <div className="col-md-6">
                <div className="input-group">
                  <div className="input-group-btn">
                    <button type="button" className="btn btn-warning dropdown-toggle" data-toggle="dropdown">Filtro&nbsp;&nbsp;
                      <span className="fa fa-caret-down"></span></button>
                    <ul className="dropdown-menu">
                      {/* <li><a href="#" onClick={() => this.setState({ filtroPlaceholder: 'Barramento' })}>Barramento</a></li>
                      <li><a href="#" onClick={() => this.setState({ filtroPlaceholder: 'Distrito' })}>Distrito</a></li> */}
                      <li><a href="#" onClick={() => this.setState({ filtroPlaceholder: 'Bairro' })}>Bairro</a></li>
                      <li><a href="#" onClick={() => this.setState({ filtroPlaceholder: 'Logradouro' })}>Logradouro</a></li>
                      <li><a href="#" onClick={() => this.setState({ filtroPlaceholder: 'OS' })}>OS</a></li>
                      <li><a href="#" onClick={() => this.setState({ filtroPlaceholder: 'Tipo de os' })}>Tipo de os</a></li>
                      <li><a href="#" onClick={() => this.setState({ filtroPlaceholder: 'Data de Abertura' })}>Data</a></li>
                      <li><a href="#" onClick={() => this.setState({ filtroPlaceholder: 'Equipe' })}>Equipe</a></li>
                    </ul>
                  </div>
                  {this.state.filtroPlaceholder != 'Distrito' &&
                    this.state.filtroPlaceholder != 'Bairro' &&
                    this.state.filtroPlaceholder != 'Tipo de os' &&
                    this.state.filtroPlaceholder != 'Data de Abertura' &&
                    this.state.filtroPlaceholder != 'Equipe' &&
                    <input type="text" onChange={this.buscaInput} className="form-control" placeholder={this.state.filtroPlaceholder} />
                  }
                  {this.state.filtroPlaceholder == 'Distrito' &&
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={this.state.lstDistritos}
                      style={{ width: '100%' }}
                      value={this.state.distrito}
                      onChange={selectedOption => this.setState({ distrito: selectedOption })}
                    />
                  }
                  {this.state.filtroPlaceholder == 'Bairro' &&
                    <Select
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      options={this.state.lstBairros}
                      style={{ width: '100%' }}
                      isClearable
                      defaultInputValue=""
                      onChange={(e) => this.inputSelectBusca(e)}
                    />
                  }
                  {this.state.filtroPlaceholder == 'Data de Abertura' &&
                    <input
                      type="date"
                      className="form-control"
                      value={this.state.dataAbertura}
                      onChange={this.buscaInput}
                    />
                  }
                  {this.state.filtroPlaceholder == 'Tipo de os' &&
                    <Select
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      options={this.state.lstTipoOs}
                      style={{ width: '100%' }}
                      isClearable
                      defaultInputValue=""
                      onChange={(e) => this.inputSelectBusca(e)}
                    />
                  }
                  {this.state.filtroPlaceholder == 'Equipe' &&
                    <Select
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      options={this.state.selectEquipe}
                      style={{ width: '100%' }}
                      isClearable
                      defaultInputValue=""
                      onChange={(e) => this.inputSelectBusca(e)}
                    />
                  }
                </div>
              </div>
              <div className="col-md-6">
                <div className="box-tools">
                  <ul className="pagination pagination-sm no-margin pull-right">
                    {this.state.pag_atual > 1 && this.state.result && this.state.result.length == this.state.paginacao &&
                      <li><a href="#" onClick={() => this.paginacao(-1)}>&laquo;</a></li>
                    }
                    <li>
                      <a>
                        {(this.state.pag_atual * (this.state.paginacao)) - 19} - {this.state.pag_atual * (this.state.paginacao)}
                      </a>
                    </li>
                    {this.state.result && this.state.result.length == this.state.paginacao &&
                      <li><a href="#" onClick={() => this.paginacao(+1)}>&raquo;</a></li>
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <p />

          {this.state.filtroValor !== "" && this.state.filtroValor.length > 0 ?(
             <div className="box" style={{ maxHeight: "81vh", margin: 0, overflow: "auto" }}>
             <div className="box-body">
               <p />
               {this.relatorio(this.state.filtroOrdensServico)}
             </div>
           </div> 
          ): (
            <div className="box" style={{ maxHeight: "81vh", margin: 0, overflow: "auto" }}>
            <div className="box-body">
              <p />
              {this.relatorio(this.state.result)}
            </div>
          </div>
          )
            }
        </section>

        <ClipLoader
        css={{ display: "block", margin: "0 auto", borderColor: "red" }}
        color={"#123abc"}
        loading={this.state.loading}
      />
      </>
    )
  }
}