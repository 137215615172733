import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import ClipLoader from "react-spinners/ClipLoader"
import { format } from 'date-fns'
import Entrada from './entrada'
import EntradaView from './entrada-view'
import { AxiosPost } from '../../../lib/utils3'
import Config from '../../../config'
import Modal from 'simple-react-modal'

export default class lstEntradaEstoque extends Component {

  state = {
    result: [],
    listaNotas: [],
    filterdNotas: [],
    material: null,
    pag_atual: 1,
    documentoExcluir: null,
    showModal: false,
    confirmaExcluir: false,
    tipoPesquisa: "documento",
  }

  async buscarMovimentacao() {
    await AxiosPost(`${Config.dominio}/php/estoque/estoque_list.php`,
      JSON.stringify({ movimentacao: 'E', estoque: 1 })
    )
      .then(async function (response) {
        let result = response.data
        let listaNotas = []
        let estoquePorNota = {}
        // Listar os materiais por documento
        result.forEach(
          (estoque) => {
            let documento = estoque.documento
            let fornecedor = estoque.fornecedor
            let index = `${documento}_${fornecedor}`
            if (documento === null) return;
            estoque.dataemissao = estoque.dataemissao !== null ? format(new Date(estoque.dataemissao), "dd/MM/yyyy") : "--"
            if (!estoquePorNota.hasOwnProperty(index)) {
              estoquePorNota[index] = {
                id: documento,
                valor: 0,
                total: 0,
                materiais: [],
                fornecedor: fornecedor,
                dataemissao: estoque.dataemissao
              }
            }
            estoquePorNota[index].materiais.push(estoque)
            estoquePorNota[index].valor += parseFloat(estoque.valor) * parseInt(estoque.quantidade)
            estoquePorNota[index].total++
          }
        )
        listaNotas = Object.values(estoquePorNota)
        this.setState({ listaNotas, result, filterdNotas: listaNotas})
      }.bind(this))
  }

  UNSAFE_componentWillMount() {
    this.buscarMovimentacao()
  }

  excluirEstoque() {
    // Criar a exclusão 
    this.setState({showModal: false, documentoExcluir: null})
  }

  relatorio() {
    if (this.state.filterdNotas) {
      if (this.state.filterdNotas.length == 0)
        return (<center><h2>Não há registros de entrada no estoque.</h2></center>)

      return (
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Nota</th>
              <th style={{ whiteSpace: "nowrap" }}>Fornecedor</th>
              <th style={{ width: "1px", textAlign: "right", whiteSpace: "nowrap" }}>Emissão</th>
              <th style={{ width: "1px", textAlign: "right", whiteSpace: "nowrap" }}>valor Total</th>
              <th style={{ width: "135px", textAlign: "center", whiteSpace: "nowrap" }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {this.state.filterdNotas.map((item, index) =>
              <tr key={index}>
                <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>{item.id}</td>
                <td style={{ whiteSpace: "nowrap" }}>{item.fornecedor}</td>
                <td style={{ textAlign: "right" }}>{item.dataemissao}</td>
                <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>R$ {Number(item.valor).toFixed(2)}</td>
                <td style={{ textAlign: "center" }}>
                  <div className="btn-group">
                    <button type="button" className="btn btn-success" 
                      onClick={() => ReactDOM.render(
                        <EntradaView id={item.id} documento={item.id} fornecedor={item.fornecedor} dataemissao={item.dataemissao} total={item.total} valor={item.valor} materiais={item.materiais} action={`view`} />, 
                        document.getElementById('Content')
                      )}><i className="fa fa-edit"></i></button>
                    {/* <button type="button" className="btn btn-danger" onClick={() => this.setState({showModal: true, documentoExcluir: item.id})} ><i className="fa fa-trash"></i></button> */}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Nota</th>
              <th style={{ whiteSpace: "nowrap" }}>Fornecedor</th>
              <th style={{ width: "1px", textAlign: "right", whiteSpace: "nowrap" }}>Emissão</th>
              <th style={{ width: "1px", textAlign: "right", whiteSpace: "nowrap" }}>valor Total</th>
              <th style={{ width: "135px", textAlign: "center", whiteSpace: "nowrap" }}>Ações</th>
            </tr>
          </tfoot>
        </table>
      )
    }
  }

  pesquisar() {
    let field = document.querySelector("#pesquisar").value
    let keySeached = "id"
    switch (this.state.tipoPesquisa) {
      case 'fornecedor':
        keySeached = 'fornecedor'
        break;
      case 'data':
        keySeached = 'dataemissao'
        break;
      default:
        keySeached = 'id'
    }
    let filterdNotas = this.state.listaNotas.filter(
      (nota) => {
        return nota[keySeached] == field
      }
    )
    this.setState({filterdNotas})
  }

  limparPesquisa() {
    this.setState({filterdNotas: this.state.listaNotas})
  }

  setTipoPesquisa(type, field) {
    document.getElementById("pesquisar").placeholder = field
    this.setState({tipoPesquisa: type})
  }

  render() {
    return (
      <>
        <Modal 
          show={this.state.showModal} 
          containerStyle={{background: 'Transparent'}} 
          transitionSpeed={400}
        >
          <div className="box box-danger">
            <div className="box-body">
              <div className="row">
                <div className="col-xs-12">
                  <center>
                    <h4>Confirma excluir essa Entrada?</h4>
                    Atenção!<br />
                    Todos os lançamentos de materiais serão removidos!
                  </center>
                </div>
              </div>
            </div>
            <div className="box-footer">
              <center>
                <button 
                  id="btnExcluir" 
                  onClick={() => this.excluirEstoque()} 
                  className="btn btn-danger btn-flat"
                >
                  Excluir
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button 
                  onClick={() => this.setState({showModal: false})} 
                  className="btn btn-warning btn-flat"
                >
                  Cancelar
                </button>
              </center>
            </div>
          </div>
        </Modal>
        <section className="content-header">
          <h1>
            Controle de Materiais
            <small>Entrada</small>
          </h1>
          <ol className="breadcrumb">
            <button className="btn btn-block btn-primary" onClick={() => ReactDOM.render(<Entrada />, document.getElementById('Content'))}> Novo lançamento</button>
          </ol>
        </section>
        <section className="content">
          <div className="box">
            <p />
            <div className="col-md-6">
              <div className="input-group">
                <div className="input-group-btn">
                  <button type="button" className="btn btn-warning dropdown-toggle" data-toggle="dropdown">Filtro&nbsp;&nbsp;
                    <span className="fa fa-caret-down"></span></button>
                  <ul className="dropdown-menu">
                    <li><a href="#" onClick={() => this.setTipoPesquisa('documento', "Documento")}>Documento</a></li>
                    <li><a href="#" onClick={() => this.setTipoPesquisa('fornecedor', "Fornecedor")}>Fornecedor</a></li>
                    <li><a href="#" onClick={() => this.setTipoPesquisa('data', "Data de Emissão")}>Data de Emissão</a></li>
                  </ul>
                </div>
                <input type="text" id="pesquisar" className="form-control" placeholder="Documento" />
                <span className="input-group-btn">
                  <button type="button" className="btn btn-info btn-flat" onClick={e => this.pesquisar(e)}>Pesquisar</button>
                  <button type="button" className="btn btn-danger" onClick={() => this.limparPesquisa()} >Limpar</button>
                </span>
              </div>
            </div>
            <div className="box-tools">

            </div>
          </div>
          <div className="box-body">
            <p />
            <p />
            <ClipLoader
              css={{ display: 'block', margin: '0 auto', borderColor: 'red' }}
              color={"#123abc"}
              loading={!this.state.listaNotas}
            />
            {this.relatorio()}
          </div>
        </section>
      </>
    )
  }
}