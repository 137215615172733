import React, { Component } from 'react'
import ReactDOM from 'react-dom' 
import ClipLoader from 'react-spinners/ClipLoader'
import Materiais from './materiais'
import { AxiosPost } from '../../../lib/utils3'
import Config from '../../../config'

export default class lstGrupoMateriais extends Component {

  state = {result: null
                 }

  relatorio() {
    if (this.state.result){
      if (this.state.result.length == 0 ) 
        return ( <center><h2>Não há Materiais cadastrados.</h2></center>  )
        
      return (
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th style={{width:"1px", whiteSpace: "nowrap"}}>Código</th>
              <th style={{width:"1px", whiteSpace: "nowrap"}}>Descrição</th>
              <th style={{width:"1px", whiteSpace: "nowrap"}}>Quantidade</th>
              <th style={{width:"1px", whiteSpace: "nowrap"}}>Grupo</th>
              <th style={{width:"135px", textAlign: "center", whiteSpace: "nowrap"}}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {this.state.result.map((item, index) =>
              <tr key={index}>
                <td style={{whiteSpace: "nowrap"}}>{item.codigo}</td>
                <td style={{whiteSpace: "nowrap"}}>{item.descricao}</td>
                <td style={{whiteSpace: "nowrap"}}>{item.quantidade_total}</td>
                <td style={{whiteSpace: "nowrap"}}>{item.grupo_desc}</td>
                <td style={{textAlign: "center"}}>
                  <div className="btn-group">
                    <button type="button" className="btn btn-success" alt="teste" onClick={()=>ReactDOM.render(<Materiais id={`${item.id}`} action={`edit`} />, document.getElementById('Content'))}><i className="fa fa-edit"></i></button>
                    <button type="button" className="btn btn-danger" onClick={()=>ReactDOM.render(<Materiais id={`${item.id}`} action={`delete`} />, document.getElementById('Content'))}><i className="fa fa-trash"></i></button>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
          <tr>
            <th style={{width:"1px", whiteSpace: "nowrap"}}>Código</th>
            <th style={{width:"1px", whiteSpace: "nowrap"}}>Descrição</th>
            <th style={{width:"1px", whiteSpace: "nowrap"}}>Quantidade</th>
            <th style={{width:"1px", whiteSpace: "nowrap"}}>Grupo</th>
            <th style={{width:"135px", textAlign: "center", whiteSpace: "nowrap"}}>Ações</th>
          </tr>
        </tfoot>
      </table>
      )
    }
  }

  async pesquisar() {
    let filtro = document.getElementById("pesquisar").placeholder
    let valor = document.getElementById("pesquisar").value
    await AxiosPost(`${Config.dominio}/php/materiais_list.php`, 
                      JSON.stringify({
                                      filtro_GrupoMateriais: filtro=="GrupoMateriais"? valor: "",
                                      filtro_descricao: filtro=="Descrição"? valor: ""
                                    })
    )
    .then( async (response) => {
      this.setState({ result: response.data })
    }) 
    .catch(erro => console.log(erro))
  }

  async componentDidMount() {
    await AxiosPost(`${Config.dominio}/php/materiais_list.php`, 
                      JSON.stringify({a:1})
    )
    .then( async (response) => {
        this.setState({result: response.data})
    }) 
    .catch(erro => console.log(erro))
  }

  render() {
    return (
      <>
        <section className="content-header"> 
          <h1>
            Materiais / Serviços
            <small>Listagem geral</small>
          </h1>
          <ol className="breadcrumb">
            <button className="btn btn-block btn-primary" onClick={()=>ReactDOM.render(<Materiais id={0} />, document.getElementById('Content'))}> Novo Material</button>
        </ol>
        
        </section>
        <section className="content">
          <div className="box">
            <p />
              <div className="col-md-6">
                <div className="input-group">
                  <div className="input-group-btn">
                    <button type="button" className="btn btn-warning dropdown-toggle" data-toggle="dropdown">Filtro&nbsp;&nbsp;
                      <span className="fa fa-caret-down"></span></button>
                    <ul className="dropdown-menu">
                      <li><a href="#" onClick={() => document.getElementById("pesquisar").placeholder="GrupoMateriais"}>Grupo de Materiais</a></li>
                      <li><a href="#" onClick={() => document.getElementById("pesquisar").placeholder="Descrição"}>Descrição</a></li>
                    </ul>
                  </div>
                  <input type="text" id="pesquisar" placeholder="Descrição" className="form-control" />
                  <span className="input-group-btn">
                    <button type="button" className="btn btn-info btn-flat" onClick={e => this.pesquisar() }>Pesquisar</button>
                  </span>
                </div>
              </div>
            </div>
          <div className="box-body">
            <ClipLoader
                css={{display: 'block', margin: '0 auto', borderColor: 'red'}}
                color={"#123abc"}
                loading={!this.state.result}
            />
            {this.state.result && this.relatorio()}
          </div>
        </section>
      </>
    )
  }
}