import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { AxiosPost } from '../../../../lib/utils3'
import DetalheOS from '../acompanhamento/detalheOS'
// import Select from 'react-select'
import Modal from 'simple-react-modal'
import ClipLoader from "react-spinners/ClipLoader"
import { format } from 'date-fns'
import makeAnimated from 'react-select/animated'
import Config from '../../../../config'

export default class callcenterConsulta extends Component {

  state = {
    result: null,
    lstStatusPesquisa: null,
    lstEquipe: [],
    tecnicos: [],
    lstBairros: [],
    bairro: [],
    contribuinte: [],
    lstDistritos: [],
    distrito: [],
    lstStatus: [],
    showFiltro: true,
    filtroPlaceholder: 'OS',
    dataAbertura: new Date(),
    loading: false,
    pag_atual: 1,
    paginacao: 20,
  }
  async listaStatus() {
    await AxiosPost(`${Config.dominio}/php/generica_list.php`,
      JSON.stringify({ tabela: 5 })
    )
      .then(async response => {
        let lista = []
        response.data.map(item => lista.push({ value: item.valor, label: item.descricao }))
        this.setState({ lstStatus: lista })
      })
      .catch(erro => console.error(erro))
  }
  relatorio() {
    if (this.state.result && this.state.result.length == 0) {
      return <center><h2></h2></center>
    } else {
      return (
        <table className="table table-hover table-striped">
          <thead>
            <tr>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>Emissão</th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>OS</th>
              <th style={{ width: "30px", whiteSpace: "nowrap" }}>Contribuinte</th>
              <th style={{ whiteSpace: "nowrap" }}>descrição do problema</th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>Situação</th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>Baixada em</th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>Bairro</th>
            </tr>
          </thead>
          <tbody>
            {this.state.result.map((item, index) =>
              <tr key={index}>
                <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>{format(new Date(item.datahora_abertura || item.data_abertura), "dd/MM/yyyy HH:mm")}</td>
                <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>{item.protocolo}</td>
                <td style={{ whiteSpace: "nowrap" }}>{item.nome_contribuinte}</td>
                <td >{item.desc_problema || item.descricao_problema}</td>
                <td style={{ whiteSpace: "nowrap" }}>{item.id_status_atual ? this.state.lstStatus.length > 0 && this.state.lstStatus.find(st => st.value == item.id_status_atual).label : ''}</td>
                <td style={{ whiteSpace: "nowrap" }}>{[4, 6, 7].find(i => i == item.id_status_atual) ? format(new Date(item.datahora_atual || item.reg_datetime), "dd/MM/yyyy HH:mm") : ''}</td>
                <td style={{ whiteSpace: "nowrap" }}>{item.bairro}</td>
              </tr>
            )}
          </tbody>
        </table>
      )
    }
  }

  async pesquisar() {

    const filterValue = this.state.filtroValor ? this.state.filtroValor : ""
    const filterSelect = this.state.filtroPlaceholder ? this.state.filtroPlaceholder : ""
    let body = { [filterSelect.toLowerCase()]: filterValue }
    if (filterSelect.toLowerCase() === "data de abertura") {
      body = { dataDeAbertura: this.state.dataAbertura }
    }
    if (filterValue != "" || typeof this.state.dataAbertura === "string") {
      this.setState({ loading: true })
      await AxiosPost(`${Config.dominio}/php/os/OS_consult_list.php`,
        JSON.stringify(body)
      )
        .then((response) => {
          this.setState({
            result: response.data,
            totalRegistros: response.data.length,
          })
        })
        .catch(erro => {
          console.error(erro)
        })
    } else {
      this.listaOrdensServico()
    }
    this.setState({ loading: false })
  }
  async getLastsCalls() {

    await AxiosPost(`${Config.dominio}/php/os/OS_consult_list.php`,
      JSON.stringify({ lastsOs: 16 })
    )
      .then((response) => {
        this.setState({
          result: response.data,
          totalRegistros: response.data.length,
        })
      })
      .catch(erro => {
        console.error(erro)
      })


  }
  async listaOrdensServico() {
    await AxiosPost(`${Config.dominio}/php/os/ordemServicoLstGeral_list.php`, JSON.stringify({
      paginacao: this.state.paginacao,
      pagina: this.state.pag_atual
    }))
      .then(async function (response) {
        this.setState({ result: response.data, loading: false })
      }.bind(this))
      .catch(erro => console.error(erro))
  }
  async paginacao(pagina) {
    let pag_atual = this.state.pag_atual
    this.setState({ pag_atual: pag_atual + pagina, loading: true, result: [] })
    await this.listaOrdensServico()
    this.setState({ loading: false })
  }

  async componentDidMount() {
    this.setState({ loading: true })
    await this.listaStatus()
    await this.listaOrdensServico()
    this.setState({ loading: false })
  }

  render() {
    return (
      <>
        <section className="content-header">
          <h1>
            Consultar protocolo de atendimento
          </h1>
          <ol className="breadcrumb">
          </ol>
        </section>
        <section className="content">

          <div className="row">
            <div className="col-md-6">
              <div className="input-group">
                <div className="input-group-btn">
                  <button type="button" className="btn btn-warning dropdown-toggle" data-toggle="dropdown">{this.state.filtroPlaceholder}&nbsp;&nbsp;
                    <span className="fa fa-caret-down"></span>
                  </button>
                  <ul className="dropdown-menu">
                    <li><a href="#" onClick={() => this.setState({ filtroPlaceholder: 'Bairro' })}>Bairro</a></li>
                    <li><a href="#" onClick={() => this.setState({ filtroPlaceholder: 'OS' })}>OS</a></li>
                    <li><a href="#" onClick={() => this.setState({ filtroPlaceholder: 'Data de Abertura' })}>Data</a></li>
                  </ul>
                </div>
                {this.state.filtroPlaceholder != 'Data de Abertura' &&
                  <input type="text" onChange={e => this.setState({ filtroValor: e.target.value })} className="form-control" placeholder={this.state.filtroPlaceholder} />
                }
                {this.state.filtroPlaceholder == 'Data de Abertura' &&
                  <input
                    type="date"
                    className="form-control"
                    value={this.state.dataAbertura}
                    onChange={event => this.setState({ dataAbertura: event.target.value })}
                  />
                }
                <span className="input-group-btn">
                  <button type="button" className="btn btn-info btn-flat" onClick={() => this.pesquisar()}>Pesquisar</button>
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="box-tools">
                <ul className="pagination pagination-sm no-margin pull-right">
                  {this.state.pag_atual > 1 && this.state.result && this.state.result.length == this.state.paginacao &&
                    <li><a href="#" onClick={() => this.paginacao(-1)}>&laquo;</a></li>
                  }
                  <li>
                    <a>
                      {(this.state.pag_atual * (this.state.paginacao)) - 19} - {this.state.pag_atual * (this.state.paginacao)}
                    </a>
                  </li>
                  {this.state.result && this.state.result.length == this.state.paginacao &&
                    <li><a href="#" onClick={() => this.paginacao(+1)}>&raquo;</a></li>
                  }
                </ul>
              </div>
            </div>
          </div>

          <p></p>        
          <div className="box" style={{ maxHeight: "81vh", margin: 0,overflow: "auto"}}>

            <div className="box-body">
              <div>
                {this.state.result && this.relatorio()}
                <ClipLoader
                  css={{ display: "block", margin: "0 auto", borderColor: "red" }}
                  color={"#123abc"}
                  loading={this.state.loading}
                />

              </div>
            </div>
          </div>
          <p />
        </section>
      </>
    )
  }
}