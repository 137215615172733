import React, { useEffect, useState } from "react";
import "../map.css";
import Equipament from '../../../../assets/equipament/lumi.png'

const widths = {
    'micro': 125,
    'small': 200,
    'medium': 250,
    'large': 300
}

const SimpleImage = (props) => {
    return (
        <>
            <div className="d-flex f-dir-col jc-sa simple-data-container image" style={{width: `${widths['medium']}px`}}>
                <div className="title">
                    <img src={Equipament} alt="Equipamento" />
                </div>
                <div className="body">
                    <span className="d-flex jc-sa info">{props.info}</span>
                </div>
            </div>
        </>
    );
}

export default SimpleImage