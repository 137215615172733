import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { AxiosPost } from '../../../lib/utils3'
import ClipLoader from "react-spinners/ClipLoader"
import DetalheOS from './acompanhamento/detalheOS'
import Select from 'react-select'
import { format } from 'date-fns'
import makeAnimated from 'react-select/animated'
import Config from '../../../config'
import { usuario } from '../../../lib/utils2'
import Modal from 'simple-react-modal'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const animatedComponents = makeAnimated()

const scrollList = {
  overflowX: 'scroll',
  width: '100%'
}

const btnStyle = {
  "width": "40px",
  "height": "34px",
  "marginLeft": "2px",
};
export default class lstOrdensDeServico extends Component {

  constructor(props) {
    super(props)
    this.state = {
      result: [],
      lstStatusPesquisa: null,
      lstEquipe: [],
      tecnicos: [],
      lstBairros: [],
      bairro: [],
      lstDistritos: [],
      distrito: [],
      lstStatus: [],
      status: [],
      showFiltro: true,
      paginacao: 20,
      totalRegistros: 0,
      pag_atual: 1,
      loadingSpin: true,
      filtroValor: "",
      filtroPlaceholder: "OS",
      selectEquipe: [],
      load: false,
      lstTipoOs: [{ value: '0', label: 'MANUTENÇÃO' },
      { value: '1', label: 'IMPLANTAÇÃO/OBRAS' }],
      tipoOs: [],
      dataFiltro: [],
      filtroOrdensServico: [],
      selectedOption: null,
      showImpressao: false,
      osImpressao: null,
      showModalPontoNaoEncontrado: false,

      enderecoPontoNaoEncontrado: null,
    bairroPontoNaoEncontrado: null,
    referenciaPontoNaoEncontrado: null,
    tipoProblema: null,
    descricaoProblema:null,
    contribuinte:null,
    data_abertura:null,
    origem:null,
    telefone:'',
    email:'',
    tipoOs:null,
    btnCancelarPNE:null,
    btnGerarProtocoloPNE:null,
    protocolo:null,
    lstProblemas: [],
    listaOrigem: [],
    listaOrigemShow: [],
    showDescObservacao: true,
    id_os: 0,
    };

    this.listaOrdensServico = this.listaOrdensServico.bind(this);
  }

  toastSuccess (mensagem) {
    toast.success(mensagem, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }

  toastError(mensagem) {
    toast.error(mensagem, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }

  async handleSubmitPontoNaoEncontrado(e) {
    e.preventDefault()
    let idUsuario = usuario().id;
    await AxiosPost(`${Config.dominio}/php/os/ordemServicoRegistroPNE2.php`,
      JSON.stringify({
        logradouro: this.state.enderecoPontoNaoEncontrado,
        bairro: this.state.bairroPontoNaoEncontrado,
        referencia: this.state.referenciaPontoNaoEncontrado,
        tipoProblema: this.state.tipoProblema,
        descricaoProblema: this.state.descricaoProblema == undefined ? '' : this.state.descricaoProblema,
        contribuinte: this.state.contribuinte,
        nomeContribuinte: this.state.contribuinte,
        telefoneContribuinte: this.state.telefone,
        emailContribuinte: this.state.email,
        data_abertura: this.state.data_abertura,
        origem: this.state.origem,
        telefone: this.state.telefone.replace(/\D/g, ''),
        sms: this.state.sms,
        email: this.state.email,
        idUsuario: this.state.idUsuario,
        tipoOs: this.state.tipoOs,
        protocolo: this.state.protocolo,
        id_os: this.state.id_os,
      })
    );
    alert("Ordem de serviço alterada com sucesso!");
      
  }

  async listarProblemas() {
    await AxiosPost(`${Config.dominio}/php/generica_list.php`, JSON.stringify({ tabela: 6 }))
      .then(async (response) => {
        let _lista = []
        response.data.map(item => _lista.push({ descricao: item.descricao, valor: item.valor }))
        this.setState({ lstProblemas: _lista, tipoProblema: _lista[0].valor })
      })
      .catch(erro => console.error(erro))
  }

  async buscaOrigem() {
    await AxiosPost(
      `${Config.dominio}/php/origem_list.php`,
      JSON.stringify({ a: 1 })
    )
    .then(async function (response) {
      let lista = []
      response.data.map(
        (item, index) => lista.push({
          id: item.id, 
          descricao: item.descricao
        })
      )
      this.setState({listaOrigem: lista})
    }.bind(this))
  }

  

  async buscaOs(id_os) {

    this.setState({ protocolo: id_os.protocolo });

    this.setState({ showModalPontoNaoEncontrado: true })

    await AxiosPost(
      `${Config.dominio}/php/os_list.php`,
      JSON.stringify({ a: 1, protocolo: id_os.protocolo})
    )
    .then(async function (response) {//psd

      console.log(response.data)

      this.setState({showDescObservacao: response.data.desc_problema});
      this.setState({enderecoPontoNaoEncontrado: response.data.rua});
      
      document.getElementById('data_abertura').value=response.data.datahora_abertura;
      this.setState({data_abertura: response.data.datahora_abertura});

      this.setState({email: response.data.email_contribuinte});
      this.setState({contribuinte: response.data.nome_contribuinte});

      this.setState({bairroPontoNaoEncontrado: response.data.bairro});
      this.setState({referenciaPontoNaoEncontrado: response.data.ref});
      this.setState({tipoProblema: response.data.defeito});

      this.setState({origem: response.data.id_origem});
      this.setState({telefone: response.data.telefone});

      this.setState({protocolo: response.data.protocolo});
      this.setState({tipoProblema: response.data.problema});

      this.setState({descricaoProblema: response.data.descricao_problema});
      document.getElementById("descricaoProblema").innerHTML = response.data.descricao_problema;
      this.setState({tipoOs: response.data.tipo_os});
      this.setState({id_os: response.data.id_os});


      document.getElementById("tipoOs1").checked = response.data.tipo_os==0?true:false;
      document.getElementById("tipoOs2").checked = response.data.tipo_os==1?true:false;



    }.bind(this))
  }

  buscaOrigem = this.buscaOrigem.bind(this)

  inputSelectBusca(e) {
    if (e?.value) {
      let arrayFiltrado =
        this.state.filtroPlaceholder === "Tipo de os"
          ? this.state.dataFiltro.filter((os) => os.tipo_os?.includes(e.value))
          : this.state.filtroPlaceholder === "Bairro"
            ? this.state.dataFiltro.filter((os) => os.bairro?.includes(e.value))
            : this.state.filtroPlaceholder === "Equipe"
              ? this.state.dataFiltro.filter((os) => parseInt(os.equipe) === parseInt(e.value))
              : []
      this.setState({ filtroOrdensServico: arrayFiltrado, filtroValor: e.value });
    } else {
      this.setState({ filtroValor: "" })
    }
  }

  buscaInput = e => {
    e.preventDefault();
    let valor = e.target.value

    let arrayFiltrado =
      valor.length > 4 && this.state.filtroPlaceholder === "OS"
        ? this.state.dataFiltro.filter((os) => os.protocolo.includes(valor))
        : valor.length > 8 && this.state.filtroPlaceholder === "Data de Abertura"
          ? this.state.dataFiltro.filter((os) => os.datahora_abertura.includes(valor))
          : valor.length > 6 && this.state.filtroPlaceholder === "Logradouro"
            ? this.state.dataFiltro.filter((os) => os.rua.includes(valor))
            : [];

    this.setState({ filtroOrdensServico: arrayFiltrado, filtroValor: valor });
  }



  async listaStatus() {
    await AxiosPost(`${Config.dominio}/php/generica_list.php`, JSON.stringify({ tabela: 5 }))
      .then(async response => {
        let lista = []
        response.data.map(item => !['0', '5'].includes(item.valor) && lista.push({ value: item.valor, label: item.descricao }))
        this.setState({ lstStatus: lista })
      })
      .catch(erro => console.error(erro))
  }

  async listaTecnico() {
    await AxiosPost(`${Config.dominio}/php/cadastro/equipe_trabalho/tecnicos/get.php`, JSON.stringify({
      a: 1
    }))
      .then(async response => {
        let lista = []
        response.data.map(item => lista.push({ value: item.id, label: item.nome }))
        this.setState({ lstEquipe: lista, selectEquipe: response.data.map(tecnico => ({ value: tecnico.id, label: tecnico.nome })) })
      })
      .catch(erro => console.error(erro))
  }

  async listaOrdensServico() {
    await AxiosPost(`${Config.dominio}/php/os/ordemServicoLstGeral_list.php`, JSON.stringify({
      paginacao: this.state.paginacao,
      pagina: this.state.pag_atual
    }))
      .then(async function (response) {
        this.setState({ result: response.data, loadingSpin: false })
      }.bind(this))
      .catch(erro => console.error(erro))
  }

  async filtro() {
    await AxiosPost(`${Config.dominio}/php/os/ordemServicoLstGeral_list.php`, JSON.stringify({
      status: null
    }))
      .then(async function (response) {
        let bairros = []
        let _bairros = []
        response.data.map(item => bairros.push(item.bairro))
        bairros = [...new Set(bairros)]
        bairros.map(item => item != "null" && _bairros.push({ value: item, label: item }))

        this.setState({
          dataFiltro: response.data,
          lstBairros: _bairros,
        })
      }.bind(this))
      .catch(erro => console.error(erro))
  }

  relatorio(arrayOs) {
    return (
      <>
        <Modal
          show={this.state.showImpressao}
          containerStyle={{ background: 'white', width: '507px', minHeight: '250px' }}
          transitionSpeed={100}
        >
          <section className="content">
            <center>
              <h3>Imprimir Ordens de Serviço</h3>
              <a href="#"
                className="btn btn-primary btn-block margin-bottom"
                onClick={() => {
                  document.forms["formListagemGeralOS"].submit()
                  this.setState({ showImpressao: false, os: null })
                }
                }
              >
                Imprimir
              </a>
              <p />
              <form
                target="_blank"
                id="formListagemGeralOS"
                action={`${Config.dominio}/php/osAbertaIndividual_listagemGeral.php`}
                method="post"
              >
                <input type="hidden" name="municipio" value={usuario().municipio.razaoSocial} />
                <input type="hidden" name="os" value={this.state.osImpressao} />
                <input type="hidden" name="empresa" value={usuario().nomeCliente} />
                <input type="hidden" name="usuario" value={usuario().nome} />
                <input type="hidden" name="local" value={sessionStorage.getItem('local')} />
                <input type="hidden" name="chaveMunicipio" value={usuario().municipio.chave} />
                <input type="checkbox" name="mapa" value="1" /> Incluir Mapa do ponto.
              </form>
              <p />
              <a href="#"
                className="btn btn-warning btn-block margin-bottom"
                onClick={() => {
                  this.setState({ showImpressao: false })
                }
                }
              >
                Fechar
              </a>
            </center>
          </section>
        </Modal>
        <Modal
          show={this.state.showModalPontoNaoEncontrado}
          containerStyle={{ background: 'white', width: '1014px', minHeight: '600px' }}
          transitionSpeed={100}
        >
          <section className="content">
            <center><h3>Dados da Ordem de Serviço - Edição</h3></center>
            <div className="col-md-6">
              <div className="box box-warning">
                <div className="box-body">
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="row">
                        <label htmlFor="logradouro">Logradouro</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={this.state.enderecoPontoNaoEncontrado}
                          onChange={e => this.setState({ enderecoPontoNaoEncontrado: e.target.value })}
                          placeholder="Nome da rua"
                          id="logradouro"
                        />
                      </div>
                      <div className="row">
                        <label htmlFor="bairro">Bairro</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={this.state.bairroPontoNaoEncontrado}
                          onChange={e => this.setState({ bairroPontoNaoEncontrado: e.target.value })}
                          placeholder="Nome do bairro"
                        />
                      </div>
                      <div className="row">
                        <label htmlFor="referencia">Referência</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={this.state.referenciaPontoNaoEncontrado}
                          onChange={e => this.setState({ referenciaPontoNaoEncontrado: e.target.value })}
                          placeholder="Referência do local"
                        />
                      </div>
                      <div className="row">
                        <label htmlFor="problemarelatado">Problema relatado</label>
                        <select
                          className="form-control"
                          id="tipoProblema"
                          value={this.state.tipoProblema}
                          onChange={e => this.setState({ tipoProblema: e.target.value, showDescObservacao: e.target.value != 99 })}
                        >
                          {
                            this.state.lstProblemas.map(opt => <option key={opt.valor} value={opt.valor}>{opt.descricao}</option>)
                          }
                        </select>
                      </div>
                      <div className="row">
                        {this.state.showDescObservacao && <label htmlFor="observacao">Observação</label>}
                        <textarea
                          style={{ margin: '0', width: '100%', height: '74px' }}
                          id="descricaoProblema"
                          name="descricaoProblema"
                          onChange={e => this.setState({ descricaoProblema: e.target.value })}
                        >
                        </textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="box box-warning">
                <div className="box-body">
                  <div className="row">
                    <div className="col-xs-12">
                      <label htmlFor="contribuinte">Contribuinte</label>
                      <input
                        type="text"
                        className="form-control"
                        id="contribuinte"
                        defaultValue={this.state.contribuinte}
                        onChange={e => this.setState({ contribuinte: e.target.value })}
                        placeholder="Nome do reclamante"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <label htmlFor="data_abertura">Data de abertura</label>
                      
                      <input type="date"
                            name="data_abertura"
                            className="form-control pull-right"
                            id="data_abertura" 
                            defaultValue={`${this.state.data_abertura}`}
                            onChange={e => this.setState({ data_abertura: e.target.value })}
                        />

                    </div>
                  </div>



                  <div className="row">
                    <div className="col-xs-12">
                      <label htmlFor="Origem">Origem</label>
                        <select
                          className="form-control select2" 
                          style={{width: '100%'}} 
                          value={this.state.origem}
                          onChange={event => this.setState({origem: event.target.value})}
                        >
                          <option value="">Selecione a origem</option>
                          {
                            this.state.listaOrigemShow && this.state.listaOrigem.map((opt, index) => <option key={index} value={opt.id}>{opt.descricao}</option>)
                          }
                        </select>

                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-12">
                      <label htmlFor="telefone">Telefone</label>
                      <div className="input-group">
                        <span className="input-group-addon">
                          <input
                            type="checkbox"
                            onChange={e => this.setState({ sms: e.target.checked ? 1 : 0 })} />
                        </span>
                        <input
                          type="text"
                          id="telefone"
                          name="telefone"
                          className="form-control"
                          defaultValue={`${this.state.telefone}`}
                          onChange={e => this.setState({ telefone: e.target.value })}
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <label htmlFor="email">E-mail</label>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        className="form-control"
                        defaultValue={`${this.state.email}`}
                        onChange={e => this.setState({ email: e.target.value })}
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-6">
                      <div className='radio'>
                        <label htmlFor='tipoOs'>
                          <input type="radio" name="tipoOs" id='tipoOs1' onChange={e => this.setState({ tipoOs: 0 })} defaultChecked />
                          MANUTENÇAO
                        </label>
                      </div>
                    </div>
                    <div className="col-xs-6">
                      <div className='radio'>
                        <label htmlFor='tipoOs'>
                          <input type="radio" name="tipoOs" id='tipoOs2' onChange={e => this.setState({ tipoOs: 1 })} />
                          IMPLANTAÇAO/OBRAS
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <br />
                    <center>
                      <button
                        onClick={e => this.setState({ showModalPontoNaoEncontrado: false })}
                        className="btn btn-warning btn-flat"
                      >
                        Cancelar
                      </button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <button
                        onClick={e => this.handleSubmitPontoNaoEncontrado(e)}
                        className="btn btn-info btn-flat"
                      >
                        Salvar
                      </button>

                    </center>
                  </div>
                </div>
                <div className="box-footer">
                  <div className="row">
                    <div className="col-xs-12">
                      <label htmlFor="protocolo">PROTOCOLO</label>
                      <div className="input-group input-group-sm">
                        <input
                          type="text"
                          className="form-control"
                          style={{ textAlign: "center", fontWeight: "bold" }}
                          id="protocolo"
                          value={this.state.protocolo}
                          placeholder=""
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Modal>
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Emissão</th>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>OS</th>
              <th style={{ width: "1px", whiteSpace: "nowrap" }}>Equipe</th>
              <th style={{ whiteSpace: "nowrap" }}>Descrição do problema</th>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Tipo OS</th>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Contribuinte</th>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Situação</th>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Baixada em</th>
              <th style={{ width: "175px", textAlign: "center", whiteSpace: "nowrap" }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {arrayOs.map((item, index) =>
              <tr key={index}>
                <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>{format(new Date(item.datahora_abertura), "dd/MM/yyyy HH:mm")}</td>
                <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>{item.protocolo}</td>
                <td style={{ whiteSpace: "nowrap" }}>{item.equipe_tecnicos}</td>
                <td style={{ whiteSpace: "nowrap" }}>{item.desc_problema}</td>
                <td style={{ whiteSpace: "nowrap" }}>{item.tipo_os === '0' ? 'MANUTENÇÃO' : item.tipo_os === '1' ? 'IMPLANTAÇÃO/OBRAS' : ''}</td>
                <td style={{ whiteSpace: "nowrap" }}>{item.nome_contribuinte}</td>
                <td style={{ whiteSpace: "nowrap" }}>{this.state.lstStatus.length > 0 && this.state.lstStatus.find(st => st.value == item.id_status_atual).label}</td>
                <td style={{ whiteSpace: "nowrap" }}>{[4, 6, 7].find(i => i == item.id_status_atual) ? format(new Date(item.datahora_atual), "dd/MM/yyyy HH:mm") : ''}</td>
                <td style={{ textAlign: "center" }}>
                  <div className="btn-group">

                    <button style={btnStyle} type="button" className="btn btn-info btn-sm" onClick={() => this.buscaOs(item)}><i className="fa fa-pencil-square-o"></i></button>



                    <button type="button" className="btn btn-success" alt="teste" onClick={() => ReactDOM.render(<DetalheOS protocolo={`${item.protocolo}`} edit={1} />, document.getElementById('Content'))}><i className="fa fa-sitemap"></i></button>
                    <button style={btnStyle} type="button" className="btn btn-info btn-sm" onClick={() => this.setState({ showImpressao: true, osImpressao: item.protocolo })}><i className="fa  fa-print"></i></button>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>

      </>
    )
  }

  async pesquisar() {
    this.state.load = true
    let filtroValor = this.state.filtroValor
    let filtroPlaceholder = this.state.filtroPlaceholder
    let _lstBairros = []
    this.state.bairro.length > 0 && this.state.bairro.map(item => _lstBairros.push(item.value))
    let _lstDistritos = []
    this.state.distrito.length > 0 && this.state.distrito.map(item => _lstDistritos.push(item.value))
    await AxiosPost(`${Config.dominio}/php/os/ordemServico_list.php`,
      JSON.stringify({
        filtro_barramento: filtroPlaceholder == 'Barramento' ? filtroValor : '',
        filtro_distrito: filtroPlaceholder == 'Distrito' ? _lstDistritos.toString() : '',
        filtro_bairro: filtroPlaceholder == 'Bairro' ? _lstBairros.toString() : '',
        filtro_rua: filtroPlaceholder == 'Logradouro' ? filtroValor : '',
        filtro_os: filtroPlaceholder == 'OS' ? filtroValor : '',
        filtro_dataAbertura: filtroPlaceholder == 'Data de Abertura' ? this.state.dataAbertura : ''
      })
    )
      .then(async function (response) {
        let bairros = []
        let _bairros = []
        let distritos = []
        let _distritos = []

        if (this.state.filtroPlaceholder == 'StatusOS' && this.state.status.length > 0) {
          response.data = response.data.filter(item => this.state.status.map(st => st.value).includes(item.id_status_atual))
        }

        response.data.map(item => bairros.push(item.bairro))
        bairros = [...new Set(bairros)]
        bairros.map(item => item != "null" && _bairros.push({ value: item, label: item }))

        response.data.map(item => distritos.push(item.distrito + '|' + item.id_distrito))
        distritos = [...new Set(distritos)]
        distritos.map(item => item != "null|null" && _distritos.push({ value: item.split("|")[1], label: item.split("|")[0] }))

        this.setState({
          result: response.data,
          totalRegistros: response.data.length,
          lstBairros: _bairros,
          lstDistritos: _distritos,
          load: false
        })
      }.bind(this))
      .catch(erro => console.error(erro))
  }

  async componentDidMount() {
    this.listaStatus()
    this.listaTecnico()
    this.listaOrdensServico()
    this.filtro()
    this.listarProblemas()
    this.buscaOrigem()
  }


  async paginacao(pagina) {
    let pag_atual = this.state.pag_atual
    this.setState({ pag_atual: pag_atual + pagina, loadingSpin: true, result: [] })
    await this.listaOrdensServico()
    this.setState({ loadingSpin: false })
  }

  render() {
    return (
      <>
        <Modal
          show={this.state.showImpressao}
          containerStyle={{ background: 'white', width: '507px', minHeight: '250px' }}
          transitionSpeed={100}
        >
          <section className="content">
            <center>
              <h3>Imprimir Ordens de Serviço</h3>
              <a href="#"
                className="btn btn-primary btn-block margin-bottom"
                onClick={() => {
                  document.forms["formPrint"].submit()
                  this.setState({ showImpressao: false })
                }
                }
              >
                Imprimir
              </a>
              <p />
              <form
                target="_blank"
                id="formPrint"
                action={`${Config.dominio}/php/osAbertaIndividual.php`}
                method="post"
              >
                <input type="hidden" name="municipio" value={usuario().municipio.razaoSocial} />
                <input type="hidden" name="os" value={this.state.osImpressao} />
                <input type="hidden" name="empresa" value={usuario().nomeCliente} />
                <input type="hidden" name="usuario" value={usuario().nome} />
                <input type="hidden" name="local" value={sessionStorage.getItem('local')} />
                <input type="hidden" name="chaveMunicipio" value={usuario().municipio.chave} />
                <input type="checkbox" name="mapa" value="1" /> Incluir Mapa do ponto.
              </form>
              <p />
              <a href="#"
                className="btn btn-warning btn-block margin-bottom"
                onClick={() => {
                  this.setState({ showImpressao: false })
                }
                }
              >
                Fechar
              </a>
            </center>
          </section>
        </Modal>



        <section className="content-header">
          <h1>
            Listagem Geral das Ordens de Serviço
          </h1>
          <ol className="breadcrumb">
          </ol>
        </section>
        <section className="content">
          <div className="row">
            <p />
            <div className="col-md-6">
              <div className="input-group">
                <div className="input-group-btn">
                  <button type="button" className="btn btn-warning dropdown-toggle" data-toggle="dropdown">Filtro&nbsp;&nbsp;
                    <span className="fa fa-caret-down"></span>
                  </button>
                  <ul className="dropdown-menu">
                    <li><a href="#" onClick={() => this.setState({ filtroPlaceholder: 'Bairro' })}>Bairro</a></li>
                    <li><a href="#" onClick={() => this.setState({ filtroPlaceholder: 'Logradouro' })}>Logradouro</a></li>
                    <li><a href="#" onClick={() => this.setState({ filtroPlaceholder: 'OS' })}>OS</a></li>
                    <li><a href="#" onClick={() => this.setState({ filtroPlaceholder: 'Tipo de os' })}>Tipo de os</a></li>
                    <li><a href="#" onClick={() => this.setState({ filtroPlaceholder: 'Data de Abertura' })}>Data</a></li>
                    <li><a href="#" onClick={() => this.setState({ filtroPlaceholder: 'Equipe' })}>Equipe</a></li>
                  </ul>
                </div>
                {this.state.filtroPlaceholder != 'Distrito' &&
                  this.state.filtroPlaceholder != 'Bairro' &&
                  this.state.filtroPlaceholder != 'Tipo de os' &&
                  this.state.filtroPlaceholder != 'Data de Abertura' &&
                  this.state.filtroPlaceholder != 'Equipe' &&
                  <input type="text" onChange={this.buscaInput} className="form-control" placeholder={this.state.filtroPlaceholder} />
                }
                {this.state.filtroPlaceholder == 'Distrito' &&
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    options={this.state.lstDistritos}
                    style={{ width: '100%' }}
                    value={this.state.distrito}
                    onChange={selectedOption => this.setState({ distrito: selectedOption })}
                  />
                }
                {this.state.filtroPlaceholder == 'Bairro' &&
                  <Select
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    options={this.state.lstBairros}
                    style={{ width: '100%' }}
                    isClearable
                    defaultInputValue=""
                    onChange={(e) => this.inputSelectBusca(e)}
                  />
                }
                {this.state.filtroPlaceholder == 'Data de Abertura' &&
                  <input
                    type="date"
                    className="form-control"
                    value={this.state.dataAbertura}
                    onChange={this.buscaInput}
                  />
                }
                {this.state.filtroPlaceholder == 'Tipo de os' &&
                  <Select
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    options={this.state.lstTipoOs}
                    style={{ width: '100%' }}
                    isClearable
                    defaultInputValue=""
                    onChange={(e) => this.inputSelectBusca(e)}
                  />
                }
                {this.state.filtroPlaceholder == 'Equipe' &&
                  <Select
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    options={this.state.selectEquipe}
                    style={{ width: '100%' }}
                    isClearable
                    defaultInputValue=""
                    onChange={(e) => this.inputSelectBusca(e)}
                  />
                }
              </div>
            </div>
            <div className="col-md-6">

              <div className="box-tools">
                <ul className="pagination pagination-sm no-margin pull-right">
                  {this.state.pag_atual > 1 && this.state.result && this.state.result.length == this.state.paginacao &&
                    <li><a href="#" onClick={() => this.paginacao(-1)}>&laquo;</a></li>
                  }
                  <li>
                    <a>
                      {(this.state.pag_atual * (this.state.paginacao)) - 19} - {this.state.pag_atual * (this.state.paginacao)}
                    </a>
                  </li>
                  {this.state.result && this.state.result.length == this.state.paginacao &&
                    <li><a href="#" onClick={() => this.paginacao(+1)}>&raquo;</a></li>
                  }
                </ul>
              </div>
            </div>

          </div>
          <p />
          <div className="box" style={{ maxHeight: "81vh", margin: 0, overflow: "auto" }}>
            <div className="box-body">
              {this.state.filtroValor !== "" && this.state.filtroValor.length > 0 ? (

                <div >
                  {this.relatorio(this.state.filtroOrdensServico)}
                </div>
              ) : (
                <div >
                  {this.relatorio(this.state.result)}
                </div>
              )
              }

            </div>
          </div>
        </section>
        <ClipLoader
          css={{ display: "block", margin: "0 auto", borderColor: "red" }}
          color={"#123abc"}
          loading={this.state.loadingSpin}
        />






      </>
    )
  }
} //senha bitbucket: L9d4TEfkKNRGpWrs6z2q