import React, { Component } from 'react'
import ReactDOM from 'react-dom' 
import ClipLoader from "react-spinners/ClipLoader"
import PontosIluminacao from './pontosIluminacao'
import PontoIluminacaoMapa from './pontoIluminacaoMapa'
import { AxiosPost }from '../../../lib/utils3'
import Config from '../../../config'

export default class lstPontosIluminacao extends Component { 

  state = {
            result: null,
            paginacao: 16,
            totalRegistros: 0,
            pag_atual: 1
          }

  async componentDidMount() {
    this.pesquisar()
  }
  paginacao(pagina) {
    // montar paginação depois de apresentar os dados
    let pag_atual = this.state.pag_atual
    this.setState({pag_atual: pag_atual+pagina})
  }

  async pesquisar() {
    let filtro = document.getElementById("pesquisar").placeholder
    let valor = document.getElementById("pesquisar").value
    await AxiosPost(`${Config.dominio}/php/pontosIluminacao/pontosIluminacao_list.php`, 
                      JSON.stringify({
                                      filtro_barramento: filtro=="Barramento"? valor: "",
                                      filtro_bairro: filtro=="Bairro"? valor: "",
                                      filtro_rua: filtro=="Logradouro"? valor: "",
                                      paginacao: this.state.paginacao,
                                      pagina: this.state.pag_atual
                                    })
    )
    .then( async (response) => {
      this.setState({ result: response.data })
    }) 
    .catch(erro => console.log(erro))
  }

  relatorio() {

    if (this.state.result){
      if (this.state.result.length == 0 ) return ( <center><h2>Não há Pontos de Iluminação cadastrados.</h2></center> )

      return (
        <table className="table table-bordered table-striped">
          <thead>
            <tr >
              <th style={{ textAlign: "center", width: "35px"}}>&nbsp;</th>
              <th style={{ textAlign: "center"}}>Barramento</th>
              <th>Bairro</th>
              <th>Logradouro</th>
              <th style={{ textAlign: "center", width: "100px"}}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {this.state.result.map((item, index) =>
             index < 15 &&
              <tr key={index}>
                <td style={{textAlign: "center" }}>
                  <img style={{height:"25px"}} alt='' src={`/assets/icons/${item.telegestao!="" ?"lampada_com_telegestao_verde.png":"lampada_sem_telegestao_verde.png" }`} />
                </td>
                <td style={{textAlign: "center" }}>{`${item.barramento}`}</td>
                <td>{`${item.bairro==null? '': item.bairro}`}</td>
                <td >{`${item.rua==null? '': item.rua}`}</td>
                <td style={{textAlign: "center"}}>
                  <div className="btn-group">
                    <button type="button" className="btn btn-success" alt="teste" onClick={()=>ReactDOM.render(<PontosIluminacao id={item.id} action={`edit`} />, document.getElementById('Content'))}><i className="fa fa-edit"></i></button>
                    <button type="button" className="btn btn-danger" onClick={()=>ReactDOM.render(<PontosIluminacao id={item.id} action={`delete`} />, document.getElementById('Content'))}><i className="fa fa-trash"></i></button>
                    {/* <button type="button" className="btn btn-info" onClick={()=>ReactDOM.render(<PontoIluminacaoMapa id={item.id} lat={item.latitude} lng={item.longitude} />, document.getElementById('Content'))}><i className="fa fa-map-o"></i></button>
                    <button type="button" className="btn btn-warning" onClick={()=>ReactDOM.render(<PontosIluminacao  id={item.id} action={`OS`} />, document.getElementById('Content'))}><i className="fa fa-bullhorn"></i></button> */}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <th style={{ textAlign: "center" }}>&nbsp;</th>
              <th style={{ textAlign: "center" }}>Barramento</th>
              <th >Bairro</th>
              <th >Logradouro</th>
              <th style={{ textAlign: "center" }}>Ações</th>
            </tr>
          </tfoot>
        </table>
      )
    }
  }

  render() {
    return (
      <>
        <section className="content-header">
          <h1>
            Pontos de Iluminação
            <small>Listagem geral</small>
          </h1>
          <ol className="breadcrumb">
            <button className="btn btn-block btn-primary" onClick={()=>ReactDOM.render(<PontosIluminacao id={0} />, document.getElementById('Content'))}> Novo Ponto de Iluminação</button>
        </ol>
        </section>
        <section className="content">
          <div className="box">
            <p />
            <div className="col-md-6">
              <div className="input-group">
                <div className="input-group-btn">
                  <button type="button" className="btn btn-warning dropdown-toggle" data-toggle="dropdown">Filtro&nbsp;&nbsp;
                    <span className="fa fa-caret-down"></span></button>
                  <ul className="dropdown-menu">
                    <li><a href="#" onClick={() => document.getElementById("pesquisar").placeholder="Barramento"}>Barramento</a></li>
                    <li><a href="#" onClick={() => document.getElementById("pesquisar").placeholder="Bairro"}>Bairro</a></li>
                    <li><a href="#" onClick={() => document.getElementById("pesquisar").placeholder="Logradouro"}>Logradouro</a></li>
                  </ul>
                </div>
                  <input type="text" id="pesquisar" className="form-control" placeholder="Barramento" />
                <span className="input-group-btn">
                  <button type="button" className="btn btn-info btn-flat" onClick={e => this.pesquisar() }>Pesquisar</button>
                </span>
              </div>
            </div>
            <div className="box-tools">
              <ul className="pagination pagination-sm no-margin pull-right">
                { this.state.pag_atual>1 &&
                  <li><a href="#" onClick={() => this.paginacao(-1)}>&laquo;</a></li>
                }
                <li>
                  <a>
                  {(this.state.pag_atual -1) * (this.state.paginacao-1) +1 } - {this.state.pag_atual * (this.state.paginacao-1)}
                  </a>
                </li>
                { this.state.result && this.state.result.length==this.state.paginacao &&
                  <li><a href="#" onClick={() => this.paginacao(+1)}>&raquo;</a></li>
                }
              </ul>
            </div>
          </div>
          <div className="box-body">
          <p />
          <p />
          <ClipLoader
                css={{display: 'block', margin: '0 auto', borderColor: 'red'}}
                color={"#123abc"}
                loading={!this.state.result}
            />
            {this.relatorio()}
          </div>
        </section>
      </>
    )
  }
}