import React, { useEffect, useState } from "react";
import "../map.css";

const widths = {
    'micro': 125,
    'small': 200,
    'medium': 250,
    'large': 300
}

const SimpleData = (props) => {
    return (
        <>
            <div className="d-flex f-dir-col jc-sa simple-data-container" style={{width: props.hasOwnProperty('width') ? `${widths[props.width]}px` : `${widths['small']}px`}}>
                <div className="title">
                    <span className="glyphicon glyphicon-align-left icon" aria-hidden="true"></span>
                    <span className="name">{props.name}</span>
                </div>
                <div className="body">
                    <span className="d-flex jc-sa info">{props.info}</span>
                </div>
            </div>
        </>
    );
}

export default SimpleData