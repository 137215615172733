import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom'
import "./map.css";
import Axios from "axios";
import { ToastContainer, toast } from 'react-toastify'
import markerPosition from "../dashboard/iconMap.png";
import { Icon } from 'leaflet';
import Modal from 'simple-react-modal'
import ComandoGrupo from "./comandoGrupo";
import DrawableMap from "./drawableMap";
import * as turf from '@turf/turf'
import { usuario } from '../../../lib/utils2'
import { tratarTipoControle } from "./utilTelegestao";
import Config from '../../../config'

const CadastroGrupo = (props) => {
  const [grupoState, setGrupoState] = useState({ name: "", description: "" });
  const [gruposState, setGruposState] = useState([]);
  const [pesquisa, setPesquisa] = useState("");
  const [pontosState, setPontosState] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [tipoModal, setTipoModal] = useState(null);
  const [grupoExclusao, setGrupoExclusao] = useState("");
  const [selectAllPontos, setSelectAllPontos] = useState(false);
  const [pontosAreaGrupoState, setPontosAreaGrupoState] = useState([]);
  const [pontosDispositivosState, setPontosDispositivosState] = useState([]);
  const [markMapState, setMarkMapState] = useState([]);
  const [neighborhoodListState, setNeighborhoodListState] = useState([])

  // const { setMapInstance } = props;
  const center = [-8.043202, -34.895833];

  const ENVIADO = {
    true: "Sim",
    false: "Não"
  }

  const TIPO_MODAL = {
    EXCLUSAO: 'EXCLUSAO',
    COMANDO: 'COMANDO'
  }


  const preencheGrupo = (event) => {
    const { name, value } = event.target;
    setGrupoState({
      ...grupoState, [name]: value
    })
  }

  const preencherPesquisa = (event) => {
    setPesquisa(event.target.value)
  }

  const handleSuggestionClick = (suggestion) => {
    setPesquisa(suggestion);
  };

  const buscarPontos = (event) => {
    let pesquisa = document.getElementById('pesquisaPontos').value;
    if (pesquisa === '') {
      alert("Informe um local na pesquisa")
      return;
    }
    limparPontos();
    Axios.get(`https://us-central1-gip-1-6c37c.cloudfunctions.net/telemetria-telemetria/bairro/${pesquisa.toUpperCase()}`)
      .then((res) => {
        const data = JSON.parse(JSON.stringify(res.data))
        setPontosState(data.map((pt) => ({ ...pt, checked: false })))
        // console.log(pontos);
      })
  }

  const limparPontos = () => {
    setPontosState([])
    setPontosAreaGrupoState([])
    setMarkMapState(pontosDispositivosState)
  }

  const buscarHistoricoComandos = (id_group) => {
    Axios.get(`https://us-central1-gip-1-6c37c.cloudfunctions.net/groups/group/command/${id_group}`)
      .then((res) => {
        const data = JSON.parse(JSON.stringify(res.data))
        const novosDados = gruposState.map((item) =>
          item.id_geofence === id_group ? { ...item, history_commands: data } : item
        );
        setGruposState(novosDados);
        // setHistoricoComandosGrupoState(data)
        // console.log(historicoComandosGrupoState);
      })
  }

  useEffect(() => {
    getGrupos();
    getPontosDispositivos();
    // listNeighborhoodCity();
  }, [])

  // Listar Grupos
  const getGrupos = async () => {
    const resp = await Axios.get(`${Config.dominio}/php/sistema/tele_list_grupo.php`);
    resp.data.forEach(d => {
      d.points = d.points ? d.points.map(p => ({ ...p, checked: true })) : []
    })
    setGruposState(resp.data);
  };

  // Listar pontos para o mapa
  const getPontosDispositivos = async () => {
    //await Axios.get("https://us-central1-gip-1-6c37c.cloudfunctions.net/groups/points")
    await Axios.get("/php/pontosIluminacao/listLightAlerta.php")
      .then((res) => {
        const novosDados = res.data.map((item) => ({ ...item, checked: true }));
        setPontosDispositivosState(novosDados);
        setMarkMapState(novosDados)
      })
  }

  const salvarGrupo = () => {
    // console.log('salvar grupo', grupoState);
    Axios.post(`${Config.dominio}/php/sistema/tele_add_grupo.php`, grupoState)
      
      .then((res) => {
        toast.info('Grupo armazenado com sucesso!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => { getGrupos() }
        })
      })
      .catch((error) => {
        // console.log(error)
      })
  }

  const atualizarGrupo = async () => {
    let novoGrupo = { ...grupoState }
    if (novoGrupo.history_commands) {
      delete novoGrupo.history_commands
    }
    Axios.put('https://us-central1-gip-1-6c37c.cloudfunctions.net/groups', novoGrupo)
      .then((res) => {
        toast.info('Grupo atualizado com sucesso!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => { getGrupos() }
        })
      })
      .catch((error) => {
        // console.log(error)
      })
  }


  const handleSubmit = async (event) => {
    event.preventDefault();

    // grupo.pontos = pontos.map(ponto => ({id: ponto.id}));
    // console.log('submit', grupoState)
    // console.log('verificacao', pontosState.length === 0 && pontosAreaGrupoState.length === 0);
    // console.log('verificacao device',pontosState.length);
    // console.log('verificacao area',pontosAreaGrupoState.length);
    if (pontosState.length === 0 && pontosAreaGrupoState.length === 0) {
      alert("Por favor! Selecione os pontos do grupo ou uma área!");
      return;
    }
    grupoState.side_points = pontosAreaGrupoState;
    // await Axios.get(`https://us-central1-gip-1-6c37c.cloudfunctions.net/groups/existe/${grupo.name}`)
    // .then((res) => {
    //   const data = JSON.parse(JSON.stringify(res.data))
    //   console.log(data);
    //   if(data?.existe) {
    //     console.log("Tada cadastrada: ",data)
    //     alert("Tag já existente no sistema")
    //   }
    //   else
    //   {
    if (grupoState.id_geofence) {
      // console.log("atualizar grupo", grupoState);
      atualizarGrupo();
    } else {
      salvarGrupo();
    }

    // }
    // })
  }

  const confirmaExcluir = async () => {
    // console.log('confirmaExcluir', grupoExclusao);

    if (grupoExclusao.id_geofence) {
      await Axios.post(`${Config.dominio}/php/sistema/tele_delete_grupo.php`, JSON.stringify({
              id: `${grupoExclusao.id}`,
              local: sessionStorage.getItem('local')
            }));
    }
    // else {
    //   await Axios.delete(`https://us-central1-gip-1-6c37c.cloudfunctions.net/groups/${grupoExclusao.id_group}`);
    // }
    toast.info('Grupo excluído com sucesso!', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      onClose: () => {
        setShowModal(false);
        getGrupos();
        limparForm();
      }
    })
  }

  const buscarDevicesGroup = async (id_group) => {
    try {
      Axios.get(`https://us-central1-gip-1-6c37c.cloudfunctions.net/groups/devices/${id_group}`)
        .then((res) => {
          const novosDados = res.data.map((item) => ({ ...item, checked: true }));
          setPontosState(novosDados);
          setMarkMapState(novosDados);
          if (novosDados.length > 0) {
            setSelectAllPontos(true);
          }
        })
    }
    catch (error) {
      // console.log(error)
    }

  }

  const cancelaExcluir = () => {
    setGrupoExclusao("");
    setShowModal(false);
  }

  const fecharModalComando = () => {
    setTipoModal(null);
    setShowModal(false);
  }

  const configurarModalExclusao = (event, id) => {
    event.stopPropagation();
    // console.log('configurarModalExclusao', id);
    setGrupoExclusao(id);
    setTipoModal(TIPO_MODAL.EXCLUSAO)
    setShowModal(true);
  }

  const configurarModalComandos = (event, grupo) => {
    event.stopPropagation();
    // console.log('configurarModalComandos', grupo);
    setTipoModal(TIPO_MODAL.COMANDO);
    setShowModal(true);
    setGrupoState(grupo);
  }

  const selectAll = () => {
    setSelectAllPontos(!selectAllPontos);
    const novosDados = pontosState.map((item) => ({ ...item, checked: !selectAllPontos }));
    setPontosState(novosDados);
  }

  const setCheck = (id_device) => {

    const novosDados = pontosState.map((item) =>
      item.id_device === id_device ? { ...item, checked: !item.checked } : item
    );
    setPontosState(novosDados);
    setMarkMapState(novosDados)
    // console.log('hidden', novosDados.find(p => p.id_device === id_device));

    const todosSelecionados = novosDados.every((item) => item.checked);
    setSelectAllPontos(todosSelecionados);
  }

  const limparForm = () => {
    setGrupoState({ id_group: "", name: "", description: "" });
    setPontosState([]);
    setPontosAreaGrupoState([])
    setMarkMapState(pontosDispositivosState);
    setSelectAllPontos(false)
  }

  const handleGrupoClick = async (grupo, index) => {
    // setPontos(grupo.pontos || []);
    buscarHistoricoComandos(grupo.id_group || grupo.id_geofence);
    if (grupo.id_group) buscarDevicesGroup(grupo.id_group);
    setGrupoState(grupo);
    setPontosAreaGrupoState(grupo.side_points || []);
    // console.log("pontos area", pontosAreaGrupoState);
  }

  const listNeighborhoodCity = async () => {
    // const city = 'Recife'
    const city = document.getElementById('name-city').value;
    if(city === '') {
      alert('Informe a cidade')
      return;
    }
    const query = `
      [out:json];
      area["name"="${city}"]->.searchArea;
      (
        relation(area.searchArea)["boundary"="administrative"]["admin_level"="10"];
      );
      out tags center qt;
    `
    try {
      const response = await fetch('https://overpass-api.de/api/interpreter', {
        method: 'POST',
        body: 'data=' + encodeURIComponent(query),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      const data = await response.json();
      // console.log(data);
      if(data.elements.length <= 0) {
        alert('Não foram encontrados informações sobre os bairros da cidade informada. Verifique as letras maiúsculas e minúsculas e tente novamente ou não existe informações sobre a cidade informada.')
      }
      setNeighborhoodListState(data.elements.map(el => (el.tags.name)))
      // console.log('neighborhood list', neighborhoodListState);
      //
    } catch (error) {
      // console.log(error);
      //
    }
    //
  }
  const searchNeighborhood = async () => {
    const neighborhood = document.getElementById('name-neighborhood').value;
    // const city = 'Recife' // document.getElementById('city').value;
    const city = document.getElementById('name-city').value;
    const query = `
                [out:json];
                area[name="${city}"]->.cityArea;
                (
                    relation(area.cityArea)["name"="${neighborhood}"];
                );
                out geom;
            `;

    try {
      const response = await fetch('https://overpass-api.de/api/interpreter', {
        method: 'POST',
        body: 'data=' + encodeURIComponent(query),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      const data = await response.json();
      if (data.elements.length > 0) {
        const relation = data.elements.find(el => el.type === 'relation' && el.tags.name === neighborhood);
        if (relation && relation.members) {
          const coords = relation.members
            .filter(member => member.type === 'way' && member.role === 'outer' && member.geometry)
            .flatMap(member => member.geometry.map(point => [point.lat, point.lon]));

          const simplifiedCoords = simplifyCoordinates(coords, 0.001); // Valor de simplificação
          // console.log('area bairro', coords);
          // console.log('municipio usuario', usuario().municipio);
          // console.log('area bairro simplificado', simplifiedCoords);
          // setPontosState(simplifiedCoords);
          setPontosAreaGrupoState(simplifiedCoords);

        } else {
          alert('Nenhum limite encontrado para o bairro informado.');
        }
      } else {
        alert('Nenhum limite encontrado para o bairro informado.');
      }
    } catch (error) {
      // console.error('Erro ao obter os dados do OSM:', error);
    }
  }

  const simplifyCoordinates = (coords, tolerance) => {
    // console.log('turf', turf);
    const geojson = turf.lineString(coords);
    const simplified = turf.simplify(geojson, { tolerance: tolerance });
    return simplified.geometry.coordinates.map(p => ({ lat: p[0], lng: p[1] }));
  }

  return (
    <>
      <ToastContainer />
      <Modal
        show={showModal && tipoModal === TIPO_MODAL.EXCLUSAO}
        containerStyle={{ background: 'Transparent' }}
        transitionSpeed={400}
      >
        <div className="box box-danger">
          <div className="box-body">
            <div className="row">
              <div className="col-xs-12">
                <center>
                  <h4>Confirma excluir esse grupo?</h4>
                </center>
              </div>
            </div>
          </div>
          <div className="box-footer">
            <center>
              <button
                id="btnExcluir"
                onClick={confirmaExcluir}
                className="btn btn-danger btn-flat"
              >
                Excluir
              </button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button
                onClick={cancelaExcluir}
                className="btn btn-warning btn-flat"
              >
                Cancelar
              </button>
            </center>
          </div>
        </div>
      </Modal>
      <Modal
        show={showModal && tipoModal === TIPO_MODAL.COMANDO}
        containerStyle={{ background: 'Transparent' }}
        transitionSpeed={400}
      >
        <div className="row" style={{ height: "600px", width: "750px", marginLeft: "5px", marginRight: "5px" }}>
          <div className="col-xs-12">
            <div className="box box-primary">
              <div className="box-header">
                <center>
                  <h2>Comandos</h2>
                </center>
              </div>
              <ComandoGrupo group={grupoState} />
              <div className="box-footer">
                <center>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button
                    onClick={fecharModalComando}
                    className="btn btn-warning btn-flat"
                  >
                    Fechar
                  </button>
                </center>
              </div>
            </div>
          </div>
        </div>

      </Modal>
      <section className="content-header">
        <h1>Cadastro de Grupos</h1>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-6">
            <form onSubmit={handleSubmit}>
              <div className="row" style={{ maxHeight: "80vh", overflow: "auto", margin: "0" }}>
                <div className="box box-primary" >
                  <div className="row" style={{ marginLeft: "5px", marginRight: "5px", paddingTop: "5px" }}>
                  <div className="col-xs-4">
                      <div className="form-group">
                        <label>Cidade</label>
                        <div className="input-group input-group-sm">
                        <input className="form-control" id="name-city"></input>
                        <span className="input-group-btn">
                            <button type="button" className="btn btn-flat btn-primary" onClick={listNeighborhoodCity}>
                              <i className="fa fa-search" aria-hidden="true"></i>
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-4">
                      <div className="form-group">
                        <label>Nome</label>
                        <div className="input-group input-group-sm">
                          <input required type="text" id="name-neighborhood" className="form-control" name="name" value={grupoState.name} onChange={preencheGrupo} list="neighborhood-list" ></input>
                          <datalist id="neighborhood-list">
                            {neighborhoodListState.map((suggestion, index) => (
                              <option
                                key={index}
                                value={suggestion}
                                onClick={() => handleSuggestionClick(suggestion)}
                              />
                            ))}
                          </datalist>
                          <span className="input-group-btn">
                            <button type="button" className="btn btn-flat btn-primary" onClick={searchNeighborhood}>
                              <i className="fa fa-search" aria-hidden="true"></i>
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-4">
                      <div className="form-group">
                        <label>Descrição</label>
                        <input required type="text" className="form-control" name="description" value={grupoState.description} onChange={preencheGrupo} ></input>
                        <div className="text-right">
                          <button className="btn btn-primary btn-flat" type="submit">Salvar</button>&nbsp;&nbsp;
                          <button className="btn btn-flat" type="button" onClick={limparForm}>Limpar</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </form>
            <h3>Grupos</h3>
            <div className="box box-primary" style={{ overflow: "auto", maxHeight: "400px" }}>
              <table className="table table-hover table-striped">
                <thead>
                  <tr>
                    <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>Nome</th>
                    <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>Descrição</th>
                    <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {gruposState.map((grupo, index) => (
                    <>
                      <tr key={grupo.id_group} data-toggle="collapse" data-parent="#accordion" data-target={`#grupo${index}`} onClick={(index) => { handleGrupoClick(grupo, index) }}>
                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>{grupo.name}</td>
                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>{grupo.description}</td>
                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                          <div className="btn-group" style={{ width: "75px" }}>
                            <button type="button" className="btn btn-success" alt="teste" onClick={(event) => { configurarModalComandos(event, grupo) }}><i className="fa fa-cog"></i></button>
                            <button type="button" className="btn btn-danger" onClick={(event) => { configurarModalExclusao(event, grupo) }}>
                              <i className="fa fa-trash"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr  >
                        <td colSpan="6">
                          <div id={`grupo${index}`} className="collapse" >
                            <div className="col-xs-12">
                              <div className="row">
                              </div>
                              <div className="row">
                                <div className="col-xs-12" style={{ overflow: "auto", maxHeight: "285px" }}>
                                  <table className="table table-striped table-bordered">
                                    <thead>
                                      <tr>
                                        <th colSpan="4" style={{ textAlign: "center" }}>Histórico de comandos</th>
                                      </tr>
                                      <tr>
                                        <th>Tipo Comando</th>
                                        <th>Criação</th>
                                        <th>Enviado</th>
                                        <th>Hora de envio</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {grupo.history_commands?.length > 0 ? grupo.history_commands.map((comando) => (<tr key={comando.create_at}>
                                        <td><div style={{ width: "110px" }}>{tratarTipoControle(comando.name)}</div></td>
                                        <td><div style={{ width: "140px" }}>{comando.create_at}</div></td>
                                        <td>{ENVIADO[comando.sent]}</td>
                                        <td><div style={{ width: "140px" }}></div>{comando.sent_date || ''}</td>
                                      </tr>)) :
                                        <tr>
                                          <td colSpan="4"><center><label>Nada há comandos para serem exibidos</label></center></td>
                                        </tr>
                                      }
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>

            </div>
          </div>
          <div className="col-xs-6">
            <div className="map-pontos">
              <DrawableMap setPontosArea={setPontosAreaGrupoState} pontosDispositivos={markMapState} pontosArea={pontosAreaGrupoState} />
            </div>
          </div>
        </div>
      </section>
    </>
  )

}

export default CadastroGrupo
