import apiService from "./BaseService"
import { usuario } from '../lib/utils2'
const ENDPOINT_PATH = "/php/pontosIluminacao/listLightPoints.php" 

const LightPoints = {
    findAll: async () => apiService.post(ENDPOINT_PATH,   JSON.stringify({
        local: sessionStorage.getItem('local'),
        chaveMunicipio: usuario().municipio.chave
    })),
};

export default LightPoints;
