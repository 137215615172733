import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import ClipLoader from "react-spinners/ClipLoader"
import ReactSwitch from 'react-switch';
import LstDispositivos from "./lstDispositivo";
import { ToastContainer, toast } from "react-toastify";
import Axios from "axios";
import { usuario } from '../../../lib/utils2'
import { processarData } from "./utilTelegestao";
import DeviceControl from "./deviceControl";
import { TIPOS_COMANDOS } from "./enumTelegestao";
import Config from '../../../config'
import { criptografar, descriptografar } from '../../../lib/utils2'
import Select from 'react-select'
import { AxiosPost } from '../../../lib/utils3';
import App from '../../../App';
import $ from 'jquery';

const initialValue = {


}



const CadastroDispositivos = (props) => {

  const [values, setValue] = useState(initialValue);
  const [listaGrupo, setListaGrupo] = useState([]);

  const grupos = async () => {
      Axios.get(`${Config.dominio}/php/sistema/tele_list_grupo.php`).then( (response) => {
          setListaGrupo(response.data);
      })

      await Axios.post(`${Config.dominio}/php/sistema/tele_list_agendamentos.php`, JSON.stringify({
              id_device: props.dispositivo.id_device
            })).then((res) => {
              document.getElementById("table_agendamento").innerHTML = res.data;


      })

  }

  const deleteAgendamento = (id, device) => {
    try {

      //psd
      Axios.post(`${Config.dominio}/php/sistema/tele_del_comando.php`, JSON.stringify({
              id_tele_agendamento: id
            }))

      Axios.post(`${Config.dominio}/php/sistema/tele_list_agendamentos.php`, JSON.stringify({
              id_device: device
            })).then((res) => {
              document.getElementById("table_agendamento").innerHTML = res.data;
      })
    }
    catch(error) {
      alert('Erro ao receber dados do dispositivo! Tente novamente');
    }
  }

  const [dispositivo,setDispositivo] = useState({id_device:"", device_ui: "", id_brooker:"", id_device_origin:"", id_poste: "",last_id_package: "",last_update: "",id_group: "",lat: "",lng: "",id_municipio: "",create_at: "",comando_manual: "", data_atualizacao:""});
  const [waitUpdateState, setWaitUpdateState] = useState(false)
  const [telemetria,setTelemetria] = useState({
    consumo: 0,
    data_atualizacao: "",
    corrente: 0,
    dimmer: 0,
    lat: 0,
    lng: 0,
    lux: 0,
    modoFotocelula: false,
    pa: 0,
    percentualBateria: 0,
    pf: 0,
    pr: 0,
    tensaoRede: 0,
    versaoDoFirmware: "",
    versaoFirmwareHex: "",
    create_at: "",
    id_device: "",
    comando_manual: "",
    isLigado: false,
    payload_raw: "",
    recifeDate: "",
    tempoLampadaLigada: 0,
    timeStamp: 0,
    type: ""})
  const [estadoLumi, setEstadoLumi] = useState(false)
  const [dimmer, setDimmer] = useState(50)
  const [scheduleListState, setScheduleListState] = useState([])
  const [errorSendCommand, setErrorSendCommand] = useState(false)

  const COMMAND_TYPE = {
    OPERATIONAL: "OPERATIONAL",
    STATUS: "STATUS",
    SCHEDULE: "SCHEDULE"
  }

  

  const atualizarTelemetria = async () => {
    try {

      var info = $("input[name='item_excluir[]']:checked")
              .map(function(){return $(this).val();}).get();

      console.log(info)

      //psd
      Axios.post(`${Config.dominio}/php/sistema/tele_zera_comandos.php`, JSON.stringify({
              id_device: `${dispositivo.id_device}`,
              informacao: info
            })).then((response) => {
         Axios.post(`${Config.dominio}/php/sistema/tele_list_agendamentos.php`, JSON.stringify({
              id_device: `${dispositivo.id_device}`
            })).then((res) => {
              document.getElementById("table_agendamento").innerHTML = res.data;
      })
      })

      

      //const res = await Axios.get(`https://us-central1-gip-1-6c37c.cloudfunctions.net/devices/obter/${dispositivo.id_device}`)
      //setDispositivo({...dispositivo,telemetria: res.data})
      //setEstadoLumi(res.data.is_access)
      //setDimmer(res.data.dimmer)
    }
    catch(error) {
      console.log(error)
      alert('Erro ao receber dados do dispositivo! Tente novamente');
    }
  }

  const atualizarStatusDispositivo = async (event) => {
    event.preventDefault();
    if(props.action === "edit") {
      // atualizarTelemetria();
      try {
        
        await atualizarTelemetria()
        toast.success('Seleção excluida!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose:() => setWaitUpdateState(false)
        });
      }
      catch(error){
        // console.log("solicitar status",error);
        setWaitUpdateState(false)
        alert('Erro ao enviar comando para o dispositivo! Tente novamente');
      }
    }
  }

  const enviarComando = async (event) => {
    event.preventDefault();
    await tratarComando(COMMAND_TYPE.OPERATIONAL);
    toast.success('O comando foi enviado ao dispositivo e atualizará o status em breve!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const cadastrarDispositivo = async () => {
    

    //await Axios.get(`https://us-central1-gip-1-6c37c.cloudfunctions.net/devices/${dispositivo.id_device}`).then((res) => {
    await Axios.post(`${Config.dominio}/php/sistema/tele_verifica_device.php`, JSON.stringify({
              id_device: `${dispositivo.id_device}`,
              local: sessionStorage.getItem('local')
            })).then((res) => {
      // console.log('cadastro existe',res.data);
      if(res.data.length > 0) {
        alert("Já existe um dispositivo cadastrado com esse ID");
        return;
      }
      // Atualiza o estado do dispositivo com a nova data
      const updatedDispositivo = { ...dispositivo, create_at: formatarData(new Date()),id_municipio:usuario().municipio.id };
      setDispositivo(updatedDispositivo);
      console.log(updatedDispositivo);

      Axios.post(`${Config.dominio}/php/sistema/tele_add_device.php`, JSON.stringify({
              id: updatedDispositivo.id,
              id_device: updatedDispositivo.id_device,
              id_brooker: updatedDispositivo.id_brooker,
              id_device_origin: updatedDispositivo.id_device_origin,
              id_group: updatedDispositivo.id_group,
              id_municipio: updatedDispositivo.id_municipio,
              id_poste: updatedDispositivo.id_poste,
              device_ui: updatedDispositivo.device_ui,
              comando_manual: updatedDispositivo.comando_manual,
              local: sessionStorage.getItem('local')
            }))
        .then(async (res) => {
          toast.success('Dispositivo cadastrado com sucesso!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          ReactDOM.render(<LstDispositivos />, document.getElementById('Content'));
        })
        .catch((error) => {
          // Handle errors
          // console.error('Erro ao enviar dispositivo:', error);
        });
    })
  }

  const atualizarDispositivo = async () => {
    await Axios.post(`${Config.dominio}/php/sistema/tele_verifica_device.php`, JSON.stringify({
              id_device: `${dispositivo.id_device}`,
              local: sessionStorage.getItem('local')
            })).then((res) => {
      // console.log('cadastro existe',res.data);
      if(res.data.length < 0) {
        alert("Dispositivo não encontrado na base de dados");
        return;
      }

      // Axios.put(`https://us-central1-gip-1-6c37c.cloudfunctions.net/devices/${dispositivo.id_device}`, dispositivo)
      Axios.post(`${Config.dominio}/php/sistema/tele_add_device.php`, JSON.stringify({
        id:dispositivo.id,
        id_device:dispositivo.id_device,
        id_brooker:dispositivo.id_brooker,
        id_device_origin:dispositivo.id_device_origin,
        id_poste: dispositivo.id_poste,
        id_group: dispositivo.id_group,
        device_ui: dispositivo.device_ui,
        comando_manual: dispositivo.comando_manual
        }))
        .then(async (res) => {
          toast.success('Dispositivo atualizado com sucesso!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          ReactDOM.render(<LstDispositivos />, document.getElementById('Content'));
        })
        .catch((error) => {
          // Handle errors
          // console.error('Erro ao enviar dispositivo:', error);
        });
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(props?.action === "edit") {
      atualizarDispositivo();
    }
    else {
      cadastrarDispositivo();
    }
  };

  const formatarData = (data) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short',
      timeZone: 'America/Sao_Paulo',
    };
    const formatData = new Intl.DateTimeFormat('pt-BR',options);
  
    return formatData.format(data);
  }

  const enviarComandoDevice = async (idCommand) => {
    /*
    await Axios.post('https://us-central1-gip-1-6c37c.cloudfunctions.net/loraWan/api/v1/tctec/downlink_claim',{
        type:"command",
        id_command:idCommand
      },{headers:{authorization: "280f3a52-ee40-4ced-8e6b-85dfbeb5a469"}})
      */
  }

  const criarComandoOperacional = async () => {
    const res = await Axios.post(
      //'https://us-central1-gip-1-6c37c.cloudfunctions.net/loraWan/api/v1/command',
      `${Config.dominio}/php/sistema/tele_add_comandos.php`,
      {
        type: 'command',
        command_type: 'control_rele',
        id_device: dispositivo.id_device,
        params: {
          dimmer: dimmer,
          status_rele: estadoLumi ? 1 : 0
        }
      },
      { headers: { authorization: '280f3a52-ee40-4ced-8e6b-85dfbeb5a469' } }
    );
    const idCommand = res.data.data
    return idCommand;
  }

  const criarComandoStatus = async () => {
    const res = await Axios.post(
      'https://us-central1-gip-1-6c37c.cloudfunctions.net/loraWan/api/v1/command',
      {
        type: 'command',
        command_type: 'get_status',
        id_device: dispositivo.id_device,
        params: {}
      },
      { headers: { authorization: '280f3a52-ee40-4ced-8e6b-85dfbeb5a469' } }
    );
    const idCommand = res.data.data
    return idCommand;
  }

  const tratarComando = async (commandType) => {
    let idCommand = "";
    // Criar comando
    if(commandType === COMMAND_TYPE.OPERATIONAL) {
      idCommand = await criarComandoOperacional();
    }
    else if(commandType === COMMAND_TYPE.STATUS) {
      idCommand = await criarComandoStatus();
    }

    try {

      // console.log('resp create command success', res);
      // await new Promise(resolve => setTimeout(resolve, 1000)); // Aguarda 1 segundo (1000 milissegundos)
      await enviarComandoDevice(idCommand);
    } catch (error) {
      // console.log('create command error', error);
      
    }
  };

  const criarComandoAgendamento = async (cmd) => {
    const res = await Axios.post(
        //'https://us-central1-gip-1-6c37c.cloudfunctions.net/loraWan/api/v1/command',
        `${Config.dominio}/php/sistema/tele_add_comandos.php`,
        cmd,
        { headers: { authorization: '280f3a52-ee40-4ced-8e6b-85dfbeb5a469' } }
      );

    const idCommand = res.data.data
    return idCommand;
  }

  const scheduleEventHandleSubmit = async (event, typeCommand) => {
    // console.log('scheduleEventHandleSubmit');
    event.preventDefault();
    let date = '';
    let cmd = null;

    // const scheduleTime = time.split(':');
    // const hour = scheduleTime[0];
    // const min = scheduleTime[1];
    // console.log('hoario', hour, min);

    const scheduleList = [];
    scheduleListState.forEach(scheduleItem => {
      const scheduleTime = scheduleItem.time.split(':');
      const hour = scheduleTime[0];
      const min = scheduleTime[1];
      const dateSplit = scheduleItem.date.split('-')
      date = new Date(dateSplit[2]+'-'+dateSplit[1]+'-'+dateSplit[0]);
      let schedule = {
        hour: parseInt(hour),
        minute: parseInt(min),
        status_rele: scheduleItem.state ? 1 : 0,
        dimmer: parseInt(scheduleItem.dimmer),
        tipo_comando: scheduleItem.type,
        data_agendamento: date
      }

      if (scheduleItem.type !== 'daily') {
        const dateSplit = scheduleItem.date.split('-')
        date = new Date(dateSplit[2]+'-'+dateSplit[1]+'-'+dateSplit[0]);
        // console.log('date', scheduleItem.date);
        // console.log('date class', date);
        schedule = { ...schedule, day: date.getUTCDate(), month: date.getUTCMonth() + 1, year: date.getYear() - 100 }
      }
      scheduleList.push(schedule)
    })

    cmd = {
      type: "command",
      command_type: typeCommand === TIPOS_COMANDOS.EVENTO ? "schedule_device_event" : "schedule_device",
      id_device: dispositivo.id_device,
      params: { scheduleList: scheduleList }
    }
    // console.log('cmd', cmd);

    try {
      const idCommand = await criarComandoAgendamento(cmd);
      await new Promise(resolve => setTimeout(resolve, 1000));
      await enviarComandoDevice(idCommand);
      toast.success('Agendamento enviado com sucesso', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    } catch (error) {
      alert('Erro ao enviar comando para o dispositivo! Tente novamente');
    }
  }



  const handleChange = (event) => {
    const { name, value } = event.target;
    setDispositivo((prevDados) => ({
      ...prevDados,
      [name]: value,
    }));
    // console.log(dispositivo)
  }
  
  useEffect(() => {
    if(props?.dispositivo && props.dispositivo.id_device){
       console.log(props.dispositivo);
      setDispositivo({...props.dispositivo, telemetria: props.dispositivo.telemetria})
      setTelemetria({...props.dispositivo.telemetria})
      setEstadoLumi(props.dispositivo.telemetria?.is_access)
      setDimmer(props.dispositivo.telemetria?.dimmer ? props.dispositivo.telemetria?.dimmer: 0)
    }
    grupos();
  },[props.dispositivo])



  return(
  <>
    <ToastContainer />
    <form onSubmit={handleSubmit}>
        <section className="content-header">
          <h1>Cadastro de Dispositivos</h1>
          <ol className="breadcrumb">
              <li>
                <button 
                  type="button" 
                  className="btn btn-danger btn-flat" 
                  onClick={() => ReactDOM.render(<LstDispositivos />, document.getElementById('Content'))}
                >Voltar</button>&nbsp;&nbsp;
                <button className="btn btn-primary" type="submit" >Salvar</button>
              </li>
            </ol>
        </section>
        <section className="content">
          <div className="box">
            <p />
            <div className="col-md-6">
            </div>
          </div>
          <div className="box-body">
            <div className="row">
              <div className="col-md-9">
                <div className="box-primary" style={{paddingTop: '5px', paddingLeft: '5px', paddingRight:'5px'}}>
                  <div className="nav-tabs-custom">
                    <ul id="tab_itens" className="nav nav-tabs">
                      <li className="active"><a href="#dispositivo" data-toggle="tab">Dispositivo</a></li>
                      <li><a href="#telegestao" data-toggle="tab">Telegestão</a></li>
                    </ul>
                    <div className="tab-content">
                      <div className="active tab-pane" id="dispositivo">
                        <div className="row">
                          <div className="col-xs-4">
                            <div className="form-group">
                              <label>ID Dispositivo</label>
                              <input className="form-control" type="text" placeholder="ID Dispositivo" name="id_device" value={"" || dispositivo?.id_device} onChange={(event) => {handleChange(event)}} required/>
                            </div>
                          </div>
                          <div className="col-xs-4">
                            <div className="form-group">
                              <label>Device UI</label>
                              <input className="form-control" type="text" placeholder="Device UI" name="device_ui" value={"" || dispositivo?.device_ui} onChange={(event) => {handleChange(event)}} required/>
                            </div>
                          </div>
                          <div className="col-xs-4">
                            <div className="form-group">
                              <label>Brooker</label>
                              <select className="form-control" name="id_brooker" value={dispositivo.id_brooker} onChange={(event) => {handleChange(event)}}>
                                <option value="">Selecione</option>
                                <option value="kore">KORE</option>
                                <option value="tctec">TCTEC</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                        <div className="col-xs-4">
                            <div className="form-group">
                              <label>Fornecedor</label>
                              <select className="form-control" name="id_device_origin" 
                              value={dispositivo.id_device_origin} onChange={(event) => {handleChange(event)}}>
                                <option value="">Selecione</option>
                                <option value="aurora">Aurora</option>
                                <option value="bottomup">BottomUp</option>
                                <option value="tron">Tron</option>
                                <option value="kdl">Kdl</option>
                                <option value="tecfab">Tecfab</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-xs-4">
                            <div className="form-group">
                              <label>ID Poste</label>
                              <input className="form-control" type="text" placeholder="Poste" name="id_poste" value={dispositivo.id_poste} onChange={(event) => {handleChange(event)}} />
                            </div>
                          </div>
                          <div className="col-xs-4">
                            <div className="form-group">
                              <label>Grupo</label>

                              <select id="id_group" name="id_group" 
                              className="form-control"
                              value={dispositivo.id_group} onChange={(event) => {handleChange(event)}}>
                                    <option value="">Selecione...</option>
                                    {listaGrupo.map(opt =>
                                        <option
                                            key={opt.name}
                                            value={opt.name}
                                        >
                                            {opt.name}
                                        </option>)}
                                </select>

                            </div>
                          </div>


                        <div className="row">
                          <div className="col-xs-4">
                            <div className="form-group">
                              <label>Tipo comando</label>
                              <select id="comando_manual" name="comando_manual" 
                              className="form-control"
                              value={dispositivo.comando_manual} onChange={(event) => {handleChange(event)}}>

                                <option value="">Selecione</option>
                                <option value="MANUAL">MANUAL</option>
                                <option value="AUTOMÁTICO">AUTOMÁTICO</option>
                              </select>
                            </div>
                          </div>
                        </div>

                          {/* <div className="col-xs-4">
                            <div className="form-group">
                              <label>Latitude</label>
                              <input className="form-control" type="text" placeholder="Latitude" />
                            </div>
                          </div> */}
                          {/* <div className="col-xs-4">
                            <div className="form-group">
                              <label>Longitude</label>
                              <input className="form-control" type="text" placeholder="Longitude" />
                            </div>
                          </div> */}
                        </div>
                        <div className="row">
                          {/* <div className="col-xs-4">
                            <div className="form-group">
                              <label>Município</label>
                              <input className="form-control" type="text" placeholder="Município" />
                            </div>
                          </div> */}
                          
                          {/* <div className="col-xs-4">
                            <div className="form-group">
                              <label>Data de criação</label>
                              <input className="form-control" type="date" />
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="tab-pane" id="telegestao">
                          <div className="row">
                            <div className="col-xs-12">
                              <div className="row">
                                <div className="col-xs-12">
                                  <div className="row">
                                    <div className="col-xs-4">
                                      <label >ID</label>                                  
                                      <input                                        
                                        type="text"
                                        className="form-control"
                                        readOnly={true}
                                        value={dispositivo.id_device}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-xs-6">
                                  <label >Data atualização</label>                                  
                                  <input                                        
                                    type="text"
                                    className="form-control"
                                    readOnly={true}
                                    value={dispositivo.data_atualizacao?dispositivo.data_atualizacao:''}
                                  />
                                </div>

                                <div className="col-xs-2">
                                  <div className="form-group">
                                    <label>Firmware</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      readOnly={true}
                                      value={dispositivo.firmware_version ? dispositivo.firmware_version : ""}
                                      id="firmware_version"
                                    />
                                  </div>
                                </div>
                                <div className="col-xs-2">
                                  <div className="form-group">
                                    <label >Estado do Poste</label>
                                    <input
                                      required
                                      type="text"
                                      className="form-control"
                                      readOnly={true}
                                      value={(dispositivo.is_access) ? "Ligado" : "Desligado"}
                                      id="is_access"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                              <div className="col-xs-4">
                                  <label >Tensão</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    readOnly={true}
                                    value={dispositivo.tensaoRede ? dispositivo.tensaoRede : "0"}
                                    id="tensaoRede"
                                  />
                                </div>
                                <div className="col-xs-4">
                                  <label>Corrente</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    readOnly={true}
                                    value={dispositivo.corrente ? dispositivo.corrente : "0"}
                                    id="corrente"
                                  />
                                </div>
                                <div className="col-xs-4">
                                  <label>Dimmer</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    readOnly={true}
                                    value={dispositivo.dimmer ? dispositivo.dimmer : "0"}
                                    id="dimmer"
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-xs-4">
                                    <div className="form-group">
                                      <label>Potência Ativa</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        readOnly={true}
                                        id="pa"
                                        value={dispositivo.pa ? dispositivo.pa : "0"}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-xs-4">
                                    <div className="form-group">
                                      <label>Potência Reativa</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        readOnly={true}
                                        id="pr"
                                        value={dispositivo.pr ? dispositivo.pr : "0"}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-xs-4">
                                    <div className="form-group">
                                      <label>Fator de Potência</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        readOnly={true}
                                        value={dispositivo.pf ? dispositivo.pf : "0"}
                                        id="pf"
                                      />
                                    </div>
                                  </div>
                              </div>
                              <div className="row d-flex flex-row align-self-baseline">
                                <div className="col-xs-4">
                                  <div className="form-group">
                                    <label>Latitude</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      readOnly={true}
                                      value={dispositivo.lat ? dispositivo.lat : ""}
                                      id="lat"
                                    />
                                  </div>
                                </div>
                                <div className="col-xs-4">
                                  <div className="form-group">
                                    <label >Longitude</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      readOnly={true}
                                      value={dispositivo.lng ? dispositivo.lng : ""}
                                      id="lng"
                                    /> </div> </div> <div className="col-xs-4
                                       text-end"> <div
                                       className="form-group " >{/* <div
                                       className=""> */} <label></label> <div
                                       className="d-flex flex-row"> <button
                                       className="btn btn-flat btn-success"
                                       type="button" onClick={(event) =>
                                       {atualizarStatusDispositivo
                                       (event)}} disabled=
                                       {props.action !== "edit" ||
                                       waitUpdateState}>Excluir agendamentos</button>
                                       <ClipLoader css={
                                       {display: 'block', margin: '0 auto',
                                       borderColor: 'green'}} color=
                                       {"#123abc"} loading={waitUpdateState}
                                    />
                                    </div>
                                    {/* </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr></hr>
                          <div className='row'>
                            <div className="col-xs-9">
                              <DeviceControl estadoLumi={estadoLumi} setEstadoLumi={setEstadoLumi} dimmer={dimmer} setDimmer={setDimmer} sentCommand={enviarComando} submit={scheduleEventHandleSubmit} scheduleList={scheduleListState} setScheduleList={setScheduleListState}/>
                              
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
  </>
  )
}

export default CadastroDispositivos;