import React, { Component } from 'react'
import ClipLoader from "react-spinners/ClipLoader"
import { AxiosPost } from '../../../lib/utils3'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { format } from 'date-fns'

import Config from '../../../config'

export default class lstSsaidaEstoque extends Component {

  state = {
    result: [],
    listaMateriaisDescartados: [],
    material: "",
    grupo: "",
    lstMaterial: [],
    lstMaterialShow: [],
    lstGrupo: [],
    pag_atual: 1
  }
  buscarGrupos = this.buscarGrupos.bind(this)
  buscarMateriais = this.buscarMateriais.bind(this)
  buscarEstoque = this.buscarEstoque.bind(this)
  
  async buscarGrupos() { 
    await AxiosPost(
      `${Config.dominio}/php/grupoMateriais_list.php`,
      JSON.stringify({ a: 1 })
    )
    .then(async function (response) {
      let lista = []
      response.data.map(item => lista.push({
        id: item.vgrupo_id, 
        grupo: item.vgrupo_desc, 
        level: item.level
      }))
      this.setState({lstGrupo: lista})
    }.bind(this))
  }

  async buscarMateriais() {
    await AxiosPost(
      `${Config.dominio}/php/materiais_list.php`,
      JSON.stringify({ a: 1 })
    )
    .then(async function (response) {
      let lista = []
      response.data.map(
        (item, index) => lista.push({
          id: item.id, 
          descricao: item.descricao, 
          codigo: item.codigo, 
          grupo: item.grupo_id, 
          unidade_medida: item.unidade_medida_desc, 
          unidades: item.unidades
        })
      )
      this.setState({lstMaterial: lista})
    }.bind(this))
  }

  async defaultSearch() {
    await AxiosPost(
      `${Config.dominio}/php/estoque/estoque_descarte.php`,
      JSON.stringify({ movimentacao: 'S', estoque: 0, limit: 30, orderColumn: "data", ordination: 'DESC'})
    )
    .then(async function (response) {
      let result = response.data
      let listaMateriaisDescartados = []
      // Listar os materiais por documento
      result.forEach(
        (estoque) => {
          listaMateriaisDescartados.push({
            id: estoque.protocolo,
            valor: parseFloat(estoque.valor),
            quantidade: parseInt(estoque.quantidade),
            valorTotal: parseFloat(estoque.valor) * parseInt(estoque.quantidade),
            material: estoque.material,
            data: format(new Date(estoque.data), "dd/MM/yyyy"),
          })
        }
      )
      this.setState({ listaMateriaisDescartados, result})
    }.bind(this))
  }

  async buscarEstoque(e) {
    e.preventDefault()
    let material = this.state.material
    await AxiosPost(
      `${Config.dominio}/php/estoque/estoque_descarte.php`,
      JSON.stringify({ movimentacao: 'S', estoque: 0, material, orderColumn: "data", ordination: 'DESC'})
    )
    .then(async function (response) {
      let result = response.data
      let listaMateriaisDescartados = []
      // Listar os materiais por documento
      result.forEach(
        (estoque) => {
          listaMateriaisDescartados.push({
            id: estoque.protocolo,
            valor: parseFloat(estoque.valor),
            quantidade: parseInt(estoque.quantidade),
            valorTotal: parseFloat(estoque.valor) * parseInt(estoque.quantidade),
            material: estoque.material,
            data: format(new Date(estoque.data), "dd/MM/yyyy")
          })
        }
      )
      this.setState({ listaMateriaisDescartados, result})
    }.bind(this))
  }

  componentDidMount() {
    this.buscarGrupos()
    this.buscarMateriais()
  }

  UNSAFE_componentWillMount() {
    this.defaultSearch()
  }

  relatorio() {
    if (this.state.listaMateriaisDescartados) {
      if (this.state.listaMateriaisDescartados.length == 0)
        return (<center><h2>Não há registros de descarte no estoque.</h2></center>)

      return (
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Protocolo</th>
              <th style={{ whiteSpace: "nowrap" }}>Material</th>
              <th style={{ width: "1px", textAlign: "right", whiteSpace: "nowrap" }}>Data de Emissão</th>
              <th style={{ width: "1px", textAlign: "right", whiteSpace: "nowrap" }}>Quantidade</th>
            </tr>
          </thead>
          <tbody>
            {this.state.listaMateriaisDescartados.map((item, index) =>
              <tr key={index}>
                <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>{item.id}</td>
                <td style={{ whiteSpace: "nowrap" }}>{item.material}</td>
                <td style={{ textAlign: "right" }}>{item.data}</td>
                <td style={{ textAlign: "right" }}>{item.quantidade}</td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Nota</th>
              <th style={{ whiteSpace: "nowrap" }}>Material</th>
              <th style={{ width: "1px", textAlign: "right", whiteSpace: "nowrap" }}>Data de Emissão</th>
              <th style={{ width: "1px", textAlign: "right", whiteSpace: "nowrap" }}>Quantidade</th>
            </tr>
          </tfoot>
        </table>
      )
    }
  }

  render() {
    return(
      <>
        <ToastContainer />
        <section className="content-header">
          <h1>
          Estoque
            <small>Descarte</small>
          </h1>
        </section>
        <section className="content">
            <form onSubmit={this.buscarEstoque}>
              <div className="box box-success">
                <div className="box-header with-border">
                  <h3 className="box-title">Descarte de material do estoque</h3>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-xs-4">
                      <div className="form-group">
                        <label htmlFor="Grupo">Grupo</label>
                        <select
                          className="form-control select2" 
                          style={{width: '100%'}} 
                          value={this.state.grupo}
                          onChange={
                            event => {
                              this.setState({grupo: event.target.value})
                              this.setState({lstMaterialShow: this.state.lstMaterial.filter(item => item.grupo == event.target.value)})
                            }
                          }
                        >
                          <option value="">Selecione o Grupo</option>
                          {
                            this.state.lstGrupo.map((opt, index) => <option key={index} value={opt.id}>{`${String("\u00A0").repeat(opt.level*4)}${opt.grupo}`}</option>)
                          }
                        </select>
                      </div>
                    </div>
                    <div className="col-xs-6">
                      <div className="form-group">
                        <label htmlFor="Material">Material</label>
                        <select
                          className="form-control select2" 
                          style={{width: '100%'}} 
                          value={this.state.material}
                          onChange={event => this.setState({material: event.target.value})}
                        >
                          <option value="">Selecione o Material</option>
                          {
                            this.state.lstMaterialShow && this.state.lstMaterialShow.map((opt, index) => <option key={index} value={opt.id}>{opt.descricao}</option>)
                          }
                        </select>
                      </div>
                    </div>
                    <div className="col-xs-2">
                      <div className="form-group">
                        <label htmlFor="Material">&nbsp;</label>
                        <button
                          type="submit"
                          className="btn btn-block btn-primary" 
                        >
                          Buscar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box-footer">      
                </div>
              </div>

            </form>
        </section>
        <section className="content">
          <div className="box">
            <div className="box-tools"></div>
          </div>
          <div className="box-body">
            <p />
            <ClipLoader
              css={{display: 'block', margin: '0 auto', borderColor: 'red'}}
              color={"#123abc"}
              loading={!this.state.result}
            />
            {this.relatorio()}
          </div>
        </section>
      </>
    )
  }
}