import React, { useState } from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import {Icon,L} from 'leaflet';
import markerPosition from "../dashboard/iconMap.png";
import AreaGrupo from "./areaGrupo";
import FlyToPoints from "./flyToPoints";


const MapaGrupo = (props) => {
  const { setMapInstance } = props;
  // const [pontosArea, setPontosArea] = useState([]);

  const position = [-8.043202, -34.895833];
  const zoomLv = 13;
  const  markIcon = new Icon({
    iconUrl: markerPosition,
    iconSize:[38,38]
  })

  return (
    <MapContainer center={position} zoom={zoomLv} style={{ height: '595px', border: "outset" }} scrollWheelZoom={true}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; OpenStreetMap France | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        maxZoom={20}
      />
      {/* {console.log("mapaGrupo pontosArea", props.pontosArea)} */}
      {/* {console.log("mapaGrupo pontosDispositivos", props.pontosDispositivos)} */}
      <FlyToPoints points={props.pontosDispositivos}  pontosArea={props.pontosArea}/>
      <AreaGrupo setMapInstance={setMapInstance} setPontosArea={props.setPontosArea} pontosArea={props.pontosArea} />
      {props.pontosDispositivos.map(mark => (
                (mark.lat && mark.lng && mark.checked) ?
                <Marker 
                  key={[mark.id_device]} 
                  position={[mark?.lat, mark?.lng]} 
                  icon={markIcon}                 
                >
                </Marker> : ""
              ))}
    </MapContainer>
  );
}

export default MapaGrupo;