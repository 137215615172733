import { TIPOS_AGENDAMENTOS, TIPOS_COMANDOS } from "./enumTelegestao";

export const processarData = (data) => {
    
  const timestampMilliseconds = data._seconds * 1000 + data._nanoseconds / 1e6;

  
  const createDate = new Date(timestampMilliseconds);

  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZone: 'America/Sao_Paulo'
  }
  
  const formattedDate = new Intl.DateTimeFormat('pt-BR',options).format(createDate);
  // const dateString = createDate.toISOString();
  return formattedDate;
}

export const tratarTipoControle = (name) => {
  let type = '';
  if(name.includes('schedule')) {
    type = TIPOS_COMANDOS.schedule_device;
  } else if (name === 'get_status') {
    type = TIPOS_COMANDOS.get_status;
  } else {
    type = TIPOS_COMANDOS.control_rele;
  }
  return type;
}
