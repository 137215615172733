import { useEffect } from "react";
import { useMap } from "react-leaflet";
import "leaflet-canvas-marker";
import L from "leaflet";
import mapIcon from "./iconMap.png"

const LeafletCanvasMarker = ({ dataPoints }) => {
  const map = useMap();

  const mountPoints = async () => {
    if (dataPoints.length === 0) return
    const points = dataPoints
    var ciLayer = L.canvasIconLayer({}).addTo(map);

    //catch actions of points \/
    // ciLayer.addOnClickListener(function (e, data) {
    //   console.log(data);
    // });
    // ciLayer.addOnHoverListener(function (e, data) {
    //   console.log(data[0].data._leaflet_id);
    // });

    var icon = L.icon({
      iconUrl: mapIcon,
      iconSize: [20, 18],
      iconAnchor: [10, 9],
    });

    var markers = [];
    points.forEach((point) => {
      if (point.geocode[0]) {
        var marker = L.marker(
          [point.geocode[0], point.geocode[1]],
          { icon: icon }
        ).bindPopup(`<h3>Barramento:  ${point.identifier}</h3>`);
        markers.push(marker);
      }
    })
    ciLayer.addLayers(markers);
  }
  useEffect(() => {
    if (!map) return;
    mountPoints()
  }, [map, dataPoints]);

  return null;
}

export default LeafletCanvasMarker