import React, { Component } from 'react'
import Axios from 'axios'
import { criptografar } from '../../lib/utils2'
import Modal from 'simple-react-modal'
import 'react-toastify/dist/ReactToastify.css'
import Config from '../../config'
import { lstCidades, usuario } from '../../lib/utils2'

var entrouagora = true 

export default class Header extends Component {

    state={

            municipio: null,
            novoMunicipio: null,
            showModal: false
          }
    confirmaTrocar = this.confirmaTrocar.bind(this)
    cancelaTrocar = this.cancelaTrocar.bind(this)


    click() {
        let posicaoMenu = sessionStorage.getItem("gip/abreMenu")
        if (!entrouagora) {
           sessionStorage.setItem("gip/abreMenu", posicaoMenu == 'false'?'true': 'false') 
        }        
    }

    solicitaConfirmacao(id) {
      this.setState({novoMunicipio: id, showModal: true})
    }
    
    // AÇÕES DO MODAL
    async confirmaTrocar() {
      let _usuario = usuario()
      await Axios.post(`${Config.dominio}/php/sistema/clienteMunicipio_list.php`,
      JSON.stringify({
                       idUsuario: _usuario.id,
                       local: sessionStorage.getItem('local')
                    })
      )
      .then(async (response) => {
        let _lstCidades = response.data
        let _municipioAtual = _lstCidades.find(item => item.id == this.state.novoMunicipio) || _lstCidades[0]
        sessionStorage.setItem('lstCidades', criptografar(JSON.stringify(_lstCidades.sort((a, b) => a.nome > b.nome? 1:-1))))

        _usuario.municipio = _municipioAtual
        await Axios.post(`${Config.dominio}/php/sistema/usuario_municipioAtual_update.php`,
                          JSON.stringify({ idUsuario: _usuario.id , municipioAtual: _usuario.municipio.id })
        )
        .then(async response => console.log(response.data))
        .catch(erro => console.log(erro))
        
         // lança na sessão o usuario
         sessionStorage.setItem('usuario', criptografar(JSON.stringify(_usuario)))  
      })
      .then (() =>  document.location = '/actions')
      .catch(erro => console.log(erro))        
    }

    cancelaTrocar() {
      this.setState({novoMunicipio: null, showModal: false})
    }
    // AÇÕES DO MODAL



    async componentDidMount() {
      if(sessionStorage.getItem("gip/abreMenu") == 'false') {
          document.getElementById('btnMenu').click()
      }
      entrouagora=false 
    }

    deletaChaveSair(){
      document.cookie = 'chave= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
      document.location='/';
    }
    

  render() {
    return (
      <>
      <Modal 
          show={this.state.showModal} 
          containerStyle={{background: 'Transparent'}} 
          transitionSpeed={400}
        >
          <div className="box box-info">
            <div className="box-body">
              <div className="row">
                <div className="col-xs-12">
                  <center>
                    <h4>Confirma trocar de município?</h4>
                  </center>
                </div>
              </div>
            </div>
            <div className="box-footer">
              <center>
                <button 
                  onClick={this.cancelaTrocar} 
                  className="btn btn-danger btn-flat"
                >
                  Não, cancelar!
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button 
                  onClick={this.confirmaTrocar} 
                  className="btn btn-success btn-flat"
                >
                  Sim, trocar!
                </button>
              </center>
            </div>
          </div>
        </Modal>
      <header className="main-header">
          <a href="#" onClick={() => document.location='/actions' } className="logo">
              <span className="logo-mini" style={{'fontSize': '12px', 'color': '#0B0B0B'}}><b>GIP</b></span>
          </a>
          <nav className="navbar navbar-static-top">
              <a href="#" id="btnMenu" className="sidebar-toggle" data-toggle="push-menu" onClick={this.click} role="button">
                  <span className="sr-only">Menu</span>
              </a>
              <div className="navbar-custom-menu">
                <ul className="nav navbar-nav">
                  <li className="dropdown tasks-menu">
                    <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                      <span className="hidden-xs">
                        { usuario().municipio.nome.toUpperCase() }
                      </span>
                    </a>
                    <ul className="dropdown-menu">
                      <li className="header">Mudar para</li>
                      <li>
                        <ul className="menu">
                          { usuario().municipio && lstCidades() && lstCidades().length > 0  &&
                            lstCidades().filter(item => item.id != usuario().municipio.id).map((opt, index) => 
                              <li key={index}><a href="#" onClick={() => this.solicitaConfirmacao(opt.id)}><i className="fa fa-map-marker"></i>&nbsp;&nbsp;{opt.nome}</a></li>
                            )
                          }
                        </ul>
                      </li>
                      <li className="footer" style={{textAlign: 'left'}}>
                        Escolha um município para gerenciar.
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown user user-menu">
                    <a href="#" className="dropdown-toggle" data-toggle="dropdown"> 
                      <i className="fa fa-user text-black" />
                      <span className="hidden-xs">{usuario().nome}</span>
                    </a>
                      <ul className="dropdown-menu">
                      <li className="user-header">
                        <p>
                          {usuario().nomeCliente}
                          <small></small>
                        </p>
                      </li>
                      <li className="user-footer">
                          <div className="pull-left">
                          <a href="#"
                          onClick={() => this.deletaChaveSair()}

                          className="btn btn-default btn-flat" id="btnSair">Sair</a>
                          </div>
                      </li>
                      </ul>
                  </li>
                  </ul>
              </div>
          </nav>
      </header>
      </>
    )
  }
}
