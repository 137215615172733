import React, { Component } from 'react'
import ReactDOM from 'react-dom' 
import Axios from 'axios'
import ClipLoader from "react-spinners/ClipLoader"
import Funcionalidade from "./funcionalidade"
import Config from '../../../config'
import { usuario } from '../../../lib/utils2'

export default class lstFuncionalidades extends Component {

  state = {
            result: null,
            CHAVEMUNICIPIO: usuario() ? usuario().municipio.chave : ""
          }

  relatorio() {
    if (this.state.result){
      if (this.state.result.length == 0 )
        return (
          <center><h2>Não há Funcionalidades cadastradas.</h2></center>
        )
        
      return (
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th style={{whiteSpace: "nowrap"}}>Funcionalidade</th>
              <th style={{width:"135px", textAlign: "center", whiteSpace: "nowrap"}}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {this.state.result.map((item, index) =>
              <tr key={index}>
                <td style={{whiteSpace: "nowrap"}}><span style={{ marginLeft: `${item.level*4}rem` }}>{item.name}</span></td>
                <td style={{textAlign: "center"}}>
                  <div className="btn-group">
                    <button type="button" className="btn btn-success" alt="teste" onClick={()=>ReactDOM.render(<Funcionalidade id={item.childID} action={`edit`} />, document.getElementById('Content'))}><i className="fa fa-edit"></i></button>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
          <tr>
            <th style={{whiteSpace: "nowrap"}}>Funcionalidade</th>
            <th style={{width:"85px", textAlign: "center", whiteSpace: "nowrap"}}>Ações</th>
          </tr>
        </tfoot>
      </table>
      )
    }
  }
  
  async componentDidMount() {
    await Axios.post(`${Config.dominio}/php/sistema/funcionalidades_list.php`,JSON.stringify({
      chave : this.state.CHAVEMUNICIPIO,
    }))
    .then( async (response) => {
      this.setState({result: response.data})
    }) 
    .catch(erro => console.error(erro))
  }

  render() {
    return (
      <>
        <section className="content-header">
          <h1>
            Funcionalidades do GIP
            <small>Listagem geral</small>
          </h1>
          <ol className="breadcrumb">
          </ol>
        </section>
        <section className="content">
          <div className="box">
            <p />
            <div className="col-md-6">
              <div className="input-group">
                
              </div>
            </div>
          </div>
          <div className="box-body">
            <p />
            <p />
            <ClipLoader
              css={{display: 'block', margin: '0 auto', borderColor: 'red'}}
              color={"#123abc"}
              loading={!this.state.result}
            />
            {this.relatorio()}
          </div>
        </section>
      </>
    )
  }
}