import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import ClipLoader from "react-spinners/ClipLoader"
import LstDispositivos from "./lstDispositivo";
import Axios from "axios";
import { Bar, BarChart, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import { addDays } from "date-fns";

const GraficoDispositivo = (props) => {
  const [parametroPesquisa,setParametroPesquisa] = useState({
    id_device: "",
    data_inicio: "",
    data_fim: ""
  });
  const [dados, setDados] = useState([])
  const [consumo, setConsumo] = useState([])
  const [waitUpdateState, setWaitUpdateState] = useState(false)

  useEffect(() => {
    if(props?.dispositivo && props.dispositivo.id_device){
      // console.log(props.dispositivo);
      setParametroPesquisa({...parametroPesquisa,id_device: props.dispositivo.id_device})
    }
  },[props.dispositivo])

  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   setParametroPesquisa((prevDados) => ({
  //     ...prevDados,
  //     [name]: value,
  //   }));
  //   // console.log(dispositivo)
  // }

  const validarPeriodo = () => {
    let valido = true;
    if (parametroPesquisa.data_inicio > parametroPesquisa.data_fim) {
      alert('Data de início do período não pode ser posterior à data final! Favor corrigir!')
      valido = false;
    }
    return valido;
  }

  const handleChangeDataInicio = (event) => {
    
    setParametroPesquisa((prevDados) => ({
      ...prevDados,
      data_inicio: event.target.value + " 00:00:00",
    }));
    // console.log(dispositivo)
  }

  const handleChangeDataFim = (event) => {
    
    setParametroPesquisa((prevDados) => ({
      ...prevDados,
      data_fim: event.target.value + " 23:59:59",
    }));
    // console.log(dispositivo)
  }


  const processarData = (data) => {
    
    const timestampMilliseconds = data._seconds * 1000 + data._nanoseconds / 1e6;

    
    const createDate = new Date(timestampMilliseconds);

    // const options = {
    //   year: 'numeric',
    //   month: 'numeric',
    //   day: 'numeric',
    //   hour: 'numeric',
    //   minute: 'numeric',
    //   second: 'numeric',
    //   timeZone: 'America/Sao_Paulo'
    // }
    
    const formattedDate = new Intl.DateTimeFormat('pt-BR').format(createDate);
    // const dateString = createDate.toISOString();
    return formattedDate;
  }

  const montarListaDatas = () => {
    const datasConsumo = []
    const data_inicio = new Date(parametroPesquisa.data_inicio)
    const data_fim = new Date(parametroPesquisa.data_fim)

    for (let i = 0, d = new Date(parametroPesquisa.data_inicio) ; d <= data_fim; d = addDays(data_inicio,++i)) {

      datasConsumo.push(d.getUTCFullYear()+'-'+(d.getUTCMonth()+1)+'-'+d.getUTCDate())      
    }
    return datasConsumo;
  }

  const buscarDados = async () => {
    const [resDados, resConsumo] = await Promise.all([
      Axios.post("https://us-central1-gip-1-6c37c.cloudfunctions.net/devices/obter-dados", parametroPesquisa),
      Axios.post("https://us-central1-gip-1-6c37c.cloudfunctions.net/devices/obter-consumo-diario", {id_device: parametroPesquisa.id_device, dates: montarListaDatas()})
    ]);
    setDados(resDados.data);
    setConsumo(resConsumo.data);
  }

  const handleProcessar = async () => {
    // const pesquisa = { ...parametroPesquisa, data_inicio: parametroPesquisa.data_inicio + " 00:00:00", data_fim: parametroPesquisa.data_fim + " 23:59:59" };
    if(!validarPeriodo()) {
      return;
    }
    setParametroPesquisa(parametroPesquisa);
    setWaitUpdateState(true)
  
    try {
      await buscarDados();
      setWaitUpdateState(false);

      // console.log(res.data);
      // const data = res.data;

      // setDados({...data, consumoDiario: consumo})
      
      
      // setDados(data.map(d => ({...d, create_at: processarData(d.create_at)})))
      // gerarGraficos(res.data);
      // console.log("dados com consumo", {...data, consumoDiario: consumo.data});
    } catch (error) {
      // console.error(error);
    } finally {
      setWaitUpdateState(false)
    }
  }
  
  const gerarGraficoTensao = () => {
    return (
      // <ResponsiveContainer height="100%" width="100%">
    <LineChart width={960} height={400} data={dados}>
      <Line type="monotone" dataKey="tension" stroke="#8884d8" fill='#8884d8' />
      <XAxis dataKey={"create_at"} angle="-45" height={120} style={{fontSize:"10px"}}></XAxis>
      <YAxis dataKey={"tension"}></YAxis>
      <Tooltip formatter={(value) => [`${value}V`, "Tensão"]}></Tooltip>
    </LineChart>
      // </ResponsiveContainer>
    )
  }

  const gerarGraficoCorrente = () => {
    return (
    <LineChart width={960} height={400} data={dados}>
      <Line type="monotone" dataKey="current" stroke="#8884d8" fill='#8884d8'/>
      <XAxis dataKey={"create_at"} angle="-45" height={120} style={{fontSize:"10px"}}></XAxis>
      <YAxis dataKey={"current"}></YAxis>
      <Tooltip formatter={(value) => [`${value}A`, "Corrente"]}></Tooltip>
    </LineChart>
    )
  }


  const gerarGraficoPa = () => {
    return (
    <LineChart width={960} height={400} data={dados}>
      <Line type="monotone" dataKey="pa" stroke="#8884d8" fill='#8884d8' />
      <XAxis dataKey={"create_at"} angle="-45" height={120} style={{fontSize:"10px"}}></XAxis>
      <YAxis dataKey={"pa"}></YAxis>
      <Tooltip formatter={(value) => [`${value}VA`, "Potência Ativa"]}></Tooltip>
    </LineChart>
    )
  }

  const gerarGraficoPr = () => {
    return (
    <LineChart width={960} height={400} data={dados}>
      <Line type="monotone" dataKey="pr" stroke="#8884d8" fill='#8884d8' />
      <XAxis dataKey={"create_at"} angle="-45" height={120} style={{fontSize:"10px"}}></XAxis>
      <YAxis dataKey={"pr"}></YAxis>
      <Tooltip formatter={(value) => [`${value}VAr`, "Potência Reativa"]}></Tooltip>
    </LineChart>
    )
  }

  const gerarGraficoPf = () => {
    return (
    <LineChart width={960} height={400} data={dados}>
      <Line type="monotone" dataKey="pf" stroke="#8884d8" fill='#8884d8' />
      <XAxis dataKey={"create_at"} angle="-45" height={120} style={{fontSize:"10px"}}></XAxis>
      <YAxis dataKey={"pf"}></YAxis>
      <Tooltip formatter={(value) => [value, "Fator de Potência"]}></Tooltip>
    </LineChart>
    )
  }

  const gerarGraficoDimmer = () => {
    return (
    <LineChart width={960} height={400} data={dados}>
      <Line type="monotone" dataKey="dimmer" stroke="#8884d8" fill='#8884d8' />
      <XAxis dataKey={"create_at"} angle="-45" height={120} style={{fontSize:"10px"}}></XAxis>
      <YAxis dataKey={"dimmer"}></YAxis>
      <Tooltip formatter={(value) => [`${value}%`, "Dimmer"]}></Tooltip>
    </LineChart>
    )
  }

  const gerarGraficoConsumo = () => {
    return (
    <BarChart width={960} height={400} data={consumo}>
      <Bar type="monotone" dataKey="consumption" stroke="#8884d8" fill='#8884d8' />
      <XAxis dataKey={"date"} angle="-45" height={120} style={{fontSize:"10px"}}></XAxis>
      <YAxis dataKey={"consumption"}></YAxis>
      <Tooltip formatter={(value) => [`${value}kWh`, "Consumo"]}></Tooltip>
    </BarChart>
    )
  }

  // const gerarGraficos = (data) => {
    
  //   console.log("gerar grafico",data);
  //   return (<>{gerarGraficoCorrente(data)}</>)
  // }
  return(
    <>
      <section className="content-header">
        <h1>Gráficos<small>{props.dispositivo.id_device}</small></h1>
        <ol className="breadcrumb">
          <li>
            <button 
              type="button" 
              className="btn btn-danger btn-flat" 
              onClick={() => ReactDOM.render(<LstDispositivos />, document.getElementById('Content'))}
            >Voltar</button>&nbsp;&nbsp;
          </li>
        </ol>
      </section>
      <section className="content">
        <div className="box">
          <div className="row" style={{marginLeft:"5px"}}>
            <div className="col-xs-2">
              <div className="form-group">
                <label>Início</label>
                <input className="form-control" name="data_inicio" type="date" onChange={(event) => {handleChangeDataInicio(event)}} />
              </div>
            </div>
            <div className="col-xs-2">
              <div className="form-group">
                <label>Fim</label>
                <input className="form-control" name="data_fim" onChange={(event) => {handleChangeDataFim(event)}} type="date" />
              </div>
            </div>
            <div className="col-xs-2">
              <div className="form-group">
                <label>Processar</label>
                <div className="d-flex flex-row">
                  <button className="btn btn-primary btn-flat" type="button" onClick={handleProcessar}>Gerar</button>
                  <ClipLoader
                    css={{display: 'block', margin: '0 auto', borderColor: '#3c8dbc'}}
                    color={"#123abc"}
                    loading={waitUpdateState}
                />
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{marginLeft:"5px"}}>
            <div className="col-xs-12">
              {dados.length === 0 ? "" : 
                <div className="form-group" style={{marginBottom: "30px"}}>
                  <label>Tensão (V)</label>     
                    {gerarGraficoTensao()}             
                  
                </div> }
            </div>
            <div className="col-xs-12 ">
              {dados.length === 0 ? "" : 
                <div className="form-group">
                  <label>Corrente (A)</label>
                  {gerarGraficoCorrente()}
                </div> }
            </div>
            <div className="col-xs-12 ">
              {dados.length === 0 ? "" : 
                <div className="form-group">
                  <label>Potência Aparente (VA)</label>
                  {gerarGraficoPa()}
                </div> }
            </div>
            <div className="col-xs-12 ">
              {dados.length === 0 ? "" : 
                <div className="form-group">
                  <label>Potencia Reativa (VAr)</label>
                  {gerarGraficoPr()}
                </div> }
            </div>
            <div className="col-xs-12 ">
              {dados.length === 0 ? "" : 
                <div className="form-group">
                  <label>Fator de Potencia (Cos(φ))</label>
                  {gerarGraficoPf()}
                </div> }
            </div>
            <div className="col-xs-12 ">
              {dados.length === 0 ? "" : 
                <div className="form-group">
                  <label>Dimmer (%)</label>
                  {gerarGraficoDimmer()}
                </div> }
            </div>
            <div className="col-xs-12 ">
              {dados.length === 0 ? "" : 
                <div className="form-group">
                  <label>Consumo (kWh))</label>
                  {gerarGraficoConsumo()}
                </div> }
            </div>
          </div>

        </div>
        <div className="box-body">
        </div>
      </section>
    </>
  )
}

export default GraficoDispositivo;