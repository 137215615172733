import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { AxiosPost } from '../../../../lib/utils3'
import ClipLoader from "react-spinners/ClipLoader"
import Tecnico from './tecnico'
import Config from "../../../../config" 


export default class lstTecnicos extends Component {

  state = {result: null}

  relatorio() {
    if (this.state.result){
      if (this.state.result.length == 0 )
        return (
          <center><h2>Não há Técnicos cadastrados.</h2></center>
        )
        
      return this.state.result && (
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th style={{whiteSpace: "nowrap"}}>Nome</th>
              <th style={{width: "1px", whiteSpace: "nowrap"}}>Contato</th>
              <th style={{width:"95px", textAlign: "center", whiteSpace: "nowrap"}}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {this.state.result.map((item, index) =>
              <tr key={index}>
                <td style={{whiteSpace: "nowrap", verticalAlign: "middle"}}>{`${item.nome}`}</td>
                <td style={{whiteSpace: "nowrap", verticalAlign: "middle"}}>{`${item.contato}`}</td>
                <td style={{textAlign: "center"}}>
                  <div className="btn-group">
                    <button type="button" className="btn btn-success" alt="teste" onClick={()=>ReactDOM.render(<Tecnico id={item.id} tecnicoData={item} action={`edit`} />, document.getElementById('Content'))}><i className="fa fa-edit"></i></button>
                    {/* <button type="button" className="btn btn-danger" onClick={()=>ReactDOM.render(<Tecnico  id={item.id} action={`delete`} />, document.getElementById('Content'))}><i className="fa fa-trash"></i></button> */}
                 </div>
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <th style={{whiteSpace: "nowrap"}}>Nome</th>
              <th style={{width: "1px", whiteSpace: "nowrap"}}>Contato</th>
              <th style={{width:"95px", textAlign: "center", whiteSpace: "nowrap"}}>Ações</th>
            </tr>
          </tfoot>
        </table>
      )
    }
  }
  async componentDidMount() {
    await AxiosPost(`${Config.dominio}/php/cadastro/equipe_trabalho/tecnicos/get.php`,
                      JSON.stringify({ a: 1 })
    )
    .then( async (response) => {
        this.setState({result: response.data})
    }) 
    .catch(erro => console.log(erro))
  }

  render() {
    return (
      <>
        <section className="content-header">
          <h1>
            Técnicos
            <small>Listagem geral</small>
          </h1>
          <ol className="breadcrumb">
            <button className="btn btn-block btn-primary" onClick={()=>ReactDOM.render(<Tecnico  id={0} action={`create`} />, document.getElementById('Content'))}> Novo Técnico</button>
        </ol>
        </section>
        <section className="content">
        <div className="box">
            <div className="box-header">
              <div className="form-group">
                <div className="row">
                  <div className="col-xs-8">
                    <div className="input-group input-group-sm">
                      <input type="text" id="pesquisar" className="form-control" />
                      <span className="input-group-btn">
                        <button type="button" className="btn btn-info btn-flat">Pesquisar</button>
                      </span>
                    </div>
                  </div>  
                </div> 
              </div>
            </div>
            <div className="box-body">
              <ClipLoader
                  css={{display: 'block', margin: '0 auto', borderColor: 'red'}}
                  color={"#123abc"}
                  loading={!this.state.result}
              />
              {this.relatorio()} 
            </div>
          </div>
        </section>
      </>
    )
  }
}