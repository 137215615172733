import React, { useState } from "react";
import { toast } from 'react-toastify'
import ReactSwitch from 'react-switch';
import Axios from "axios";
import DeviceControl from "./deviceControl";


const ComandoGrupo = (props) => {
  const [dimmerState, setDimmerState] = useState("50");
  const [estadoLumiState, setEstadoLumiState] = useState(false);
  const [scheduleListState, setScheduleListState] = useState([])
  // const [readOnlyDataState, setReadOnlyDataState] = useState(true);

  const TIPOS_COMANDOS = {
    DIARIO: 'daily',
    EVENTO: 'event'
  }

  // const tratarReadOnlyData = (elemento) => {
  //   const valor = elemento.options[elemento.selectedIndex].value
  //   console.log("tipo comando", valor);
  //   setReadOnlyDataState(valor !== TIPOS_COMANDOS.EVENTO)
  // }

  const tratarComando = async () => {

      let cmd = {
        type: 'command',
        command_type: 'control_rele',
        params: {
          dimmer: dimmerState,
          status_rele: estadoLumiState ? 1 : 0,
        }
      }

      cmd = { ...cmd, id_geofence: props.group.id_geofence }

      const idCommand = await criarComando(cmd);
      await new Promise(resolve => setTimeout(resolve, 1000));
      await enviarComandoGrupo(idCommand);
  }

  const enviarComando = async (event) => {
    event.preventDefault();
    try {
      // console.log("comando", props.group);
      await tratarComando();
      toast.success('O comando foi enviado com sucesso', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      alert('Erro ao enviar comando para o grupo! Certifique-se de que existe dispositivos na região e tente novamente!');
    }
  }

  const enviarComandoGrupo = async (idCommand) => {
    await Axios.post('https://us-central1-gip-1-6c37c.cloudfunctions.net/loraWan/api/v1/tctec/downlink_claim', {
      type: "command",
      id_command: idCommand
    }, { headers: { authorization: "280f3a52-ee40-4ced-8e6b-85dfbeb5a469" } }).then((resp) => {
      // console.log("resp send command success", resp);
    })
  }

  const criarComando = async (cmd) => {
    let res = "";
    res = await Axios.post(
      'https://us-central1-gip-1-6c37c.cloudfunctions.net/loraWan/api/v1/command_geofence',
      cmd,
      { headers: { authorization: '280f3a52-ee40-4ced-8e6b-85dfbeb5a469' } }
    );

    const idCommand = res.data.data
    return idCommand;
  }

  const handleSubmit = async (event, typeCommand) => {
    event.preventDefault();
    let date = '';
    let cmd = null;

    const scheduleList = [];
    scheduleListState.forEach(scheduleItem => {
      const scheduleTime = scheduleItem.time.split(':');
      const hour = scheduleTime[0];
      const min = scheduleTime[1];
      let schedule = {
        hour: parseInt(hour),
        minute: parseInt(min),
        status_rele: scheduleItem.state ? 1 : 0,
        dimmer: parseInt(scheduleItem.dimmer)
      }

      if (scheduleItem.type === TIPOS_COMANDOS.EVENTO) {
        const dateSplit = scheduleItem.date.split('-')
        date = new Date(dateSplit[2] + '-' + dateSplit[1] + '-' + dateSplit[0]);
        // console.log('date', scheduleItem.date);
        // console.log('date class', date);
        schedule = { ...schedule, day: date.getUTCDate(), month: date.getUTCMonth() + 1, year: date.getYear() - 100 }
      }
      scheduleList.push(schedule)
    })

    cmd = {
      type: "command",
      command_type: typeCommand === TIPOS_COMANDOS.EVENTO ? "schedule_device_event" : "schedule_device",
      id_geofence: props.group.id_geofence,
      params: { scheduleList: scheduleList }
    }
    // console.log('cmd', cmd);

    try {
      const idCommand = await criarComando(cmd);
      await new Promise(resolve => setTimeout(resolve, 1000));
      await enviarComandoGrupo(idCommand);
      toast.success('Agendamento enviado com sucesso', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    } catch (error) {
      alert('Erro ao enviar comando para o grupo! Certifique-se de que existe dispositivos na região e tente novamente!');
    }

  }

  return (
    <>
      <DeviceControl estadoLumi={estadoLumiState} setEstadoLumi={setEstadoLumiState} dimmer={dimmerState} setDimmer={setDimmerState} sentCommand={enviarComando} submit={handleSubmit} scheduleList={scheduleListState} setScheduleList={setScheduleListState} />
    </>
  )

}

export default ComandoGrupo;
