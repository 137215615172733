import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import { AxiosPost } from "../../../lib/utils3";
import ClipLoader from "react-spinners/ClipLoader";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ToastContainer, toast } from "react-toastify";
import ModalCancelar from "../../common/ModalCancelar";
import { format } from "date-fns";
import { usuario } from "../../../lib/utils2";

import Config from "../../../config";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import RowsTable from "../../common/RowsTable";
import AtencaoMsg from "../../common/AtencaoMsg";
import Checkbox from "../../common/Checkbox";

const animatedComponents = makeAnimated();

const Autorizacao = () => {
  const [ordensServico, setOrdensServico] = useState([]);
  const [filtroPlaceholder, setFiltroPlaceholder] = useState("OS");
  const [protocolo, setProtocolo] = useState("");
  const [descProblema, setDescProblema] = useState("");
  const [indexOs, setIndexOs] = useState([]);
  const [filtroValor, setFiltroValor] = useState([]);
  const [bairros, setBairros] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModalCancelarOs, setShowModalCancelarOs] = useState(false);
  
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  let vencimento = 0;

  const lstTipoOs = [
    { value: "", label: "..." },
    { value: "0", label: "MANUTENÇÃO" },
    { value: "1", label: "IMPLANTAÇÃO/OBRAS" },
  ];

  const filtroOrdensServico =
    filtroValor.length > 0 && filtroPlaceholder === "OS"
      ? ordensServico.filter((os) => os.protocolo.includes(filtroValor))
      : filtroValor.length > 0 && filtroPlaceholder === "Logradouro"
      ? ordensServico.filter((os) => os.rua.includes(filtroValor))
      : filtroValor.length > 0 && filtroPlaceholder === "Data de Abertura"
      ? ordensServico.filter((os) => os.datahora_atual.includes(filtroValor))
      : filtroPlaceholder === "Tipo"
      ? ordensServico.filter((os) => os.tipo_os.includes(filtroValor))
      : filtroPlaceholder === "Bairro"
      ? ordensServico.filter((os) => os.bairro.includes(filtroValor))
      : [];

  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(ordensServico.map(li => li.protocolo));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = e => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id));
    }
  };

  const toastSuccess = (mensagem) => {
    toast.success(mensagem, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  };

  const toastError = (mensagem) => {
    toast.error(mensagem, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  };

  const dataDiff = (dataAberturaOs) => {

    let dataAtual = moment().format("DD/MM/YYYY HH:mm:ss");
    let d2 = dataAberturaOs;
    let diff = moment(dataAtual, "DD/MM/YYYY HH:mm:ss").diff(
      moment(d2, "DD/MM/YYYY HH:mm:ss")
    );
    let dias = moment.duration(diff).asDays();

    if (dias <= 7) {
      vencimento = dias;
      return 0;
    } else if (dias > 7 && dias <= 8) {
      vencimento = dias;
      return 1;
    } else if (dias >= 9) {
      vencimento = dias;
      return 2;
    }
  };

  const autorizarOs = () => {

    AxiosPost(
      `${Config.dominio}/php/os/ordemServico_autorizar.php`,
      JSON.stringify({ lista: isCheck.toString() })
    )
      .then((response) => {
        if (response.data === "ok") {
          for (let protocol of isCheck){
            let index = ordensServico.findIndex((os) => os.protocolo.includes(protocol))
            ordensServico.splice(index, 1)
            setIsCheck([]);
          } 
          setIsCheckAll(false);
          toastSuccess("Ordem serviço autorizada com sucesso!");
        } else if(isCheck.length === 0) {
          toastError("Por favor, selecione pelo menos uma ordem de serviço.");
        }
      })
      .catch((erro) => {
        console.log(erro);
        toastError("Falha na autorização. Por favor, tente novamente.");
      });
  };

  const cancelaOs = (_protocolo, index) => {
    AxiosPost(
      `${Config.dominio}/php/os/ordemServico_cancelar.php`,
      JSON.stringify({
        protocolo: _protocolo,
        local: sessionStorage.getItem("local"),
        chaveMunicipio: usuario().municipio.chave,
      })
    ).then((response) => {
      if (response.data === "OK") {
     
        ordensServico.splice(index, 1);
        setShowModalCancelarOs(false);
      } else {
        toastError(
          "A ordem de serviço não foi cancelada. Por favor, tente novamente."
        );
      }
    });
  };

  const listaOrdensdeServico =  () => {
    AxiosPost(
      `${Config.dominio}/php/os/ordemServicoAutorizacao_list.php`,
      JSON.stringify({  })
    ).then((response) => {
      setOrdensServico(response.data);
      setLoading(false);

      let _bairros = [];
      let bairros = response.data.map((os) => os.bairro);
      bairros.map(
        (os) => os !== "null" && _bairros.push({ value: os, label: os })
      );
      _bairros.unshift({ value: "", label: "..." });

      setBairros(_bairros);
    }).catch((erro) => console.log(erro))
  };

  useEffect(() => {
    listaOrdensdeServico();
  }, []);


  const rowsTable = (osArray) => {
    return osArray.map((os, index) => {
      return (
        <tr key={index}>
          <td>
            <Checkbox
              type="checkbox"
              name={os.protocolo}
              id={os.protocolo}
              handleClick={handleClick}
              isChecked={isCheck.filter(check => check == os.protocolo).length}
            />
            
          </td>
          <RowsTable
            classe={"mailbox-subject"}
            dataDiff={() =>
              dataDiff(format(new Date(os.datahora_atual), "dd/MM/yyyy HH:mm"))
            }
            dataLine={os.protocolo}
          />

          <RowsTable
            classe={"mailbox-subject"}
            dataDiff={() =>
              dataDiff(format(new Date(os.datahora_atual), "dd/MM/yyyy HH:mm"))
            }
            dataLine={os.rua}
          />

          <RowsTable
            classe={"mailbox-subject"}
            dataDiff={() =>
              dataDiff(format(new Date(os.datahora_atual), "dd/MM/yyyy HH:mm"))
            }
            dataLine={os.bairro}
          />

          <RowsTable
            classe={"mailbox-subject"}
            dataDiff={() =>
              dataDiff(format(new Date(os.datahora_atual), "dd/MM/yyyy HH:mm"))
            }
            dataLine={os.desc_problema}
          />

          <RowsTable
            classe={"mailbox-subject"}
            dataDiff={() =>
              dataDiff(format(new Date(os.datahora_atual), "dd/MM/yyyy HH:mm"))
            }
            dataLine={os.nome_contribuinte}
          />

          <td className="mailbox-subject">
            {dataDiff(
              format(new Date(os.datahora_atual), "dd/MM/yyyy HH:mm")
            ) === 0 ? (
              <p className="text-green">
                {os.tipo_os === "0"
                  ? "MANUTENÇÃO"
                  : os.tipo_os === "1"
                  ? "IMPLANTAÇÃO/OBRAS"
                  : ""}
              </p>
            ) : dataDiff(
                format(new Date(os.datahora_atual), "dd/MM/yyyy HH:mm")
              ) === 1 ? (
              <p className="text-yellow">
                {os.tipo_os === "0"
                  ? "MANUTENÇÃO"
                  : os.tipo_os === "1"
                  ? "IMPLANTAÇÃO/OBRAS"
                  : ""}
              </p>
            ) : (
              <p className="text-red">
                {os.tipo_os === "0"
                  ? "MANUTENÇÃO"
                  : os.tipo_os === "1"
                  ? "IMPLANTAÇÃO/OBRAS"
                  : ""}
              </p>
            )}
          </td>

          <RowsTable
            classe={"mailbox-subject"}
            dataDiff={() =>
              dataDiff(format(new Date(os.datahora_atual), "dd/MM/yyyy HH:mm"))
            }
            dataLine={format(new Date(os.datahora_atual), "dd/MM/yyyy HH:mm")}
          />

          <td className="mailbox-date">
            {dataDiff(format(new Date(os.datahora_atual), "dd/MM/yyyy HH:mm")) 
            === 0 ? (
              <small className="label bg-green">{Math.round(vencimento)}</small>
            ) : dataDiff(format(new Date(os.datahora_atual), "dd/MM/yyyy HH:mm")
              ) === 1 ? (
              <small className="label bg-yellow">
                {Math.round(vencimento)}
              </small>
            ) : (
              <small className="label bg-red">{Math.round(vencimento)}</small>
            )}
          </td>

          <td>
            <button
              type="button"
              className="btn btn-danger"
              alt="cancelar os"
              onClick={() =>
                setShowModalCancelarOs(true) ||
                setProtocolo(os.protocolo) ||
                setDescProblema(os.desc_problema) ||
                setIndexOs(index)
              }
            >
              <i className="fa fa-trash"></i>
            </button>
          </td>
        </tr>
      );
    });
  };

  const componenteOrdensServico = () => {
    if (ordensServico.length === 0) {
      return (
        <center>
          <h2>Não há Ordens de Serviço cadastradas.</h2>
        </center>
      );
    } else {
      return (
        <div className="col-md-12">
          <div className="box box-primary">
            <div className="box-header with-border">
              <h3 className="box-title">Ordens a Autorizar</h3>
            </div>
            <div className="box-body">
              <div className="row">
                <div className="col-md-9">
                  <div className="mailbox-controls">
                  <Checkbox
                    type="checkbox"
                    name="selectAll"
                    id="selectAll"
                    handleClick={handleSelectAll}
                    isChecked={isCheckAll}
                  />
                    &nbsp;&nbsp;&nbsp;&nbsp;Marcar / desmarcar todas
                  </div>
                </div>
                <div className="col-md-3">
                  <a
                    href="#"
                    className="btn btn-primary btn-block margin-bottom"
                    onClick={() => autorizarOs(indexOs)}
                  >
                    Autorizar
                  </a>
                </div>
              </div>
              <div className="table-responsive mailbox-messages">
                <table className="table table-hover table-striped">
                  <thead>
                    <tr>
                      <th></th>
                      <th>OS</th>
                      <th>Logradouro</th>
                      <th>Bairro</th>
                      <th>Problema</th>
                      <th>Contribuinte</th>
                      <th>Tipo OS</th>
                      <th>Data/Hora</th>
                      <th>Dias</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  {filtroValor.length > 0 ? (
                    <tbody>{rowsTable(filtroOrdensServico)}</tbody>
                  ) : (
                    <tbody>{rowsTable(ordensServico)}</tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <ToastContainer />
      <ModalCancelar
        showCancelarOS={showModalCancelarOs}
        protocolo={protocolo}
        handleCancelar={() => cancelaOs(protocolo, indexOs)}
        onClose={() => setShowModalCancelarOs(false)}
        desc_problema={descProblema}
        index={indexOs}
      />
      <section className="content-header">
        <h1>
          Ordem de Serviço
          <small>Autorização de execução</small>
        </h1>
        <ol className="breadcrumb"></ol>
      </section>

      <section className="content">
        <div className="row">
          <div className="box">
            <div className="box">
              <p />
              <div className="col-md-6">
                <div className="input-group">
                  <div className="input-group-btn">
                    <button
                      type="button"
                      className="btn btn-warning dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      Filtro&nbsp;&nbsp;
                      <span className="fa fa-caret-down"></span>
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a
                          href="#"
                          onClick={() =>
                            setFiltroPlaceholder("Bairro") || setFiltroValor([])
                          }
                        >
                          Bairro
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={() =>
                            setFiltroPlaceholder("Logradouro") ||
                            setFiltroValor([])
                          }
                        >
                          Logradouro
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={() =>
                            setFiltroPlaceholder("OS") || setFiltroValor([])
                          }
                        >
                          OS
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={() =>
                            setFiltroPlaceholder("Tipo") || setFiltroValor([])
                          }
                        >
                          Tipo de os
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={() =>
                            setFiltroPlaceholder("Data de Abertura") ||
                            setFiltroValor([])
                          }
                        >
                          Data
                        </a>
                      </li>
                    </ul>
                  </div>
                  {filtroPlaceholder !== "Bairro" &&
                    filtroPlaceholder !== "Tipo" &&
                    filtroPlaceholder !== "Data de Abertura" && (
                      <input
                        type="text"
                        name={filtroPlaceholder}
                        value={filtroValor}
                        onChange={(e) => setFiltroValor(e.target.value)}
                        className="form-control"
                        placeholder={filtroPlaceholder}
                      />
                    )}
                  {filtroPlaceholder === "Bairro" && (
                    <Select
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      options={bairros}
                      style={{ width: "100%" }}
                      value={filtroValor.value}
                      onChange={(selectedOption) =>
                        setFiltroValor(selectedOption.value)
                      }
                    />
                  )}
                  {filtroPlaceholder === "Tipo" && (
                    <Select
                      closeMenuOnSelect={true}
                      name={filtroPlaceholder}
                      components={animatedComponents}
                      options={lstTipoOs}
                      style={{ width: "100%" }}
                      value={filtroValor.value}
                      onChange={(selectedOption) =>
                        setFiltroValor(selectedOption.value)
                      }
                    />
                  )}
                  {filtroPlaceholder === "Data de Abertura" && (
                    <input
                      type="date"
                      name={filtroPlaceholder}
                      className="form-control"
                      value={filtroValor}
                      onChange={(e) => setFiltroValor(e.target.value)}
                    />
                  )}
                </div>
              </div>
            </div>
            <p />
          </div>
        </div>
        <p />
        <div className="row">
          {ordensServico.length > 0 && componenteOrdensServico()}
          {ordensServico.length === 0 && loading === false && <AtencaoMsg 
                                                                titulo={"Não há ordem de serviço para ser autorizada."}
                                                                mensagem={"Por favor, crie um ordem de serviço antes de distribuir."}/>}
        </div>
        <div className="box-footer no-padding"></div>
      </section>
      <ClipLoader
        css={{ display: "block", margin: "0 auto", borderColor: "red" }}
        color={"#123abc"}
        loading={loading}
      />
    </>
  );
};
export default Autorizacao;